import React, { Suspense, useEffect } from "react";
// import styles from "./css/terms-and-conditions.module.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";

import { fetchPrivacy } from "./../../data/privacySlice";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);

  const { privacy, loading, error } = useSelector((state) => state.privacy);
  useEffect(() => {
    dispatch(fetchPrivacy(language));
  }, [dispatch, language]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#d8b560", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{privacy?.pageMetatagTitle}</title>
            <link
              rel="canonical"
              href={`https://www.dharahotels.com/${language}/aswan/benben-hotel/privacy-policy`}
            />
            <meta
              name="description"
              content={privacy?.pageMetatagDescription}
            />
            <meta property="og:title" content={privacy?.metatagTitleFacebook} />
            <meta
              property="og:description"
              content={privacy?.metatagDescriptionFacebook}
            />
            <meta name="twitter:title" content={privacy?.metatagTitleTwitter} />
            <meta
              name="twitter:description"
              content={privacy?.metatagDescriptionTwitter}
            />
          </Helmet>

          <section class="page-banner">
            <div
              class="image-layer"
              style={{
                backgroundImage: `url(${
                  isMobile
                    ? privacy?.pageBannerMobile
                    : isTablet
                    ? privacy?.pageBannerTablet
                    : privacy?.pageBannerPC
                })`,
              }}
            ></div>
            <div class="banner-bottom-pattern"></div>

            <div class="banner-inner">
              <div class="auto-container">
                <div class="inner-container clearfix centered">
                  <h1>{privacy?.pageTitle}</h1>
                  <div class="page-nav">
                    <ul class="bread-crumb clearfix">
                      <li>
                        <Link to="/">{t("Home")}</Link>
                      </li>
                      <li class="active">{privacy?.pageTitle}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            class="sidebar-page-container blog-single"
            style={{ paddingTop: "1px" }}
          >
            <div class="auto-container">
              <div class="row clearfix">
                <div class="content-side col-lg-12 col-md-12 col-sm-12">
                  <div class="blog-content">
                    <div class="post-details">
                      <div class="inner-box">
                        <div
                          class="lower-box"
                          dangerouslySetInnerHTML={{
                            __html: privacy?.pageText,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default PrivacyPolicy;
