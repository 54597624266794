import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const CookieConsent = () => {
  const { t } = useTranslation();
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    // Check if the user has already responded to the cookie consent
    const hasAccepted = getCookie("cookiesAccepted");
    const hasDeclined = getCookie("cookiesDeclined");

    if (!hasAccepted && !hasDeclined) {
      setShowConsent(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    setCookie("cookiesAccepted", "true", 365);
    setShowConsent(false);
    loadGoogleAnalytics();
  };

  const handleDeclineCookies = () => {
    setCookie("cookiesDeclined", "true", 365);
    setShowConsent(false);
  };

  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = `${name}=${value || ""}${expires}; path=/`;
  };

  const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  const loadGoogleAnalytics = () => {
    (function (i, s, o, g, r, a, m) {
      i["GoogleAnalyticsObject"] = r;
      i[r] =
        i[r] ||
        function () {
          (i[r].q = i[r].q || []).push(arguments);
        };
      i[r].l = 1 * new Date();
      a = s.createElement(o);
      m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(
      window,
      document,
      "script",
      "https://www.google-analytics.com/analytics.js",
      "ga"
    );

    if (window.ga) {
      window.ga("create", "G-X5QC70RMD9", "auto");
      window.ga("send", "pageview");
    }
  };

  return (
    showConsent && (
      <div
        style={{
          display: "block",
          position: "fixed",
          zIndex: 1000,
          bottom: 0,
          width: "100%",
          backgroundColor: "#000",
          color: "#fff",
          textAlign: "center",
          padding: "15px",
        }}
      >
        {t(
          "This website uses cookies to ensure you get the best experience on our website"
        )}
        .
        <button
          onClick={handleAcceptCookies}
          style={{
            marginLeft: "10px",
            padding: "5px 10px",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            cursor: "pointer",
          }}
        >
          {t("Accept Cookies")}
        </button>
        <button
          onClick={handleDeclineCookies}
          style={{
            marginLeft: "10px",
            padding: "5px 10px",
            backgroundColor: "#f44336",
            color: "white",
            border: "none",
            cursor: "pointer",
          }}
        >
          {t("Decline Cookies")}
        </button>
      </div>
    )
  );
};

export default CookieConsent;
