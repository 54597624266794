import React, { useEffect, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";
import { fetchFacilities } from "../../data/facilities/facilitiesSlice";

const Activities = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();

  const language = useSelector((state) => state.language.language);
  const { facilities, loading, error } = useSelector(
    (state) => state.facilities
  );
  useEffect(() => {
    dispatch(fetchFacilities(language));
  }, [dispatch, language]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#d8b560", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          {" "}
          <Helmet>
            <title>{facilities.pageDetails?.pageMetatagTitle}</title>
            <link
              rel="canonical"
              href={`https://www.dharahotels.com/${language}/aswan/benben-hotel/activities`}
            />
            <meta
              name="description"
              content={facilities.pageDetails?.pageMetatagDescription}
            />
            <meta
              property="og:title"
              content={facilities.pageDetails?.metatagTitleFacebook}
            />
            <meta
              property="og:description"
              content={facilities.pageDetails?.metatagDescriptionFacebook}
            />
            <meta
              name="twitter:title"
              content={facilities.pageDetails?.metatagTitleTwitter}
            />
            <meta
              name="twitter:description"
              content={facilities.pageDetails?.metatagDescriptionTwitter}
            />

            <style>
              {` .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }`}
            </style>

            <style>
              {`   
  .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }
 

 
   .starting-from {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
        border: 0px solid #000 !important;
        display: -webkit-flex !important;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        font-size: 13px !important;
        -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
        margin: 0 auto !important;
        max-width: 1000px !important;
        padding: 1px !important;
    }

    .starting-from--txt {
        color: #21acb7;
    }

    .starting-from .starting-from--price {
        font-size: 22px !important;
        font-weight: 600;
        color: #d8b560 !important;
    }`}
            </style>
          </Helmet>
          <section class="page-banner">
            <div
              class="image-layer"
              style={{
                backgroundImage: `url(${
                  isMobile
                    ? facilities.pageDetails?.pageBannerMobile
                    : isTablet
                    ? facilities.pageDetails?.pageBannerTablet
                    : facilities.pageDetails?.pageBannerPC
                })`,
              }}
            ></div>
            <div class="banner-bottom-pattern"></div>

            <div class="banner-inner">
              <div class="auto-container">
                <div class="inner-container clearfix centered">
                  <h1>{facilities.pageDetails?.pageTitle}</h1>
                  <div class="page-nav">
                    <ul class="bread-crumb clearfix">
                      <li>
                        <Link to="/">{t("Home")}</Link>
                      </li>
                      <li class="active">
                        {facilities.pageDetails?.pageTitle}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="rooms-section rooms-list">
            <div className="auto-container">
              <div className="sec-title centered">
                <div className="lower-text">
                  <p>
                    <br />
                  </p>
                </div>
              </div>

              <div className="rooms-box">
                {facilities.facilities?.map((facility, index) => (
                  <div className="room-block-four" key={index}>
                    <div className="inner-box">
                      <div className="image-box">
                        <figure className="image-layer">
                          <img
                            src={facility.facilityPhotoHome}
                            alt={facility.facilityName}
                            title={facility.facilityName}
                          />
                        </figure>
                        <div
                          className="image-layer"
                          style={{
                            backgroundImage: `url(${facility.facilityPhotoHome})`,
                          }}
                        ></div>
                        {facility.facilityPageGalleries?.map((image, i) => (
                          <a
                            key={i}
                            className={`${
                              i === 0 && "overlink"
                            } lightbox-image`}
                            data-fancybox={`gallery-${index}`}
                            href={image.photoFile}
                          ></a>
                        ))}
                      </div>
                      <div className="content-box">
                        <h4 style={{ fontSize: "40px", fontWeight: "bold" }}>
                          <Link to="meditation">{facility.facilityName}</Link>
                        </h4>
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{
                            __html: facility.facilitySummery,
                          }}
                        />

                        <div
                          className="link-row clearfix"
                          style={{ paddingTop: "50px" }}
                        >
                          {facility.isMoreDetails ? (
                            <div
                              className="link-box"
                              style={{ float: "right" }}
                            >
                              <Link
                                to={`/activities/${facility.facilityUrl}`}
                                className="theme-btn btn-style-one"
                              >
                                <span className="btn-title">
                                  {t("Explore")}
                                </span>
                              </Link>
                            </div>
                          ) : (
                            <div
                              className="link-box"
                              style={{ float: "right" }}
                            >
                              <Link
                                to="https://www.kemetale.com/sailing"
                                className="theme-btn btn-style-one"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="btn-title">
                                  {t("Explore")}
                                </span>
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Activities;
