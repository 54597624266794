import {
  faAngleUp,
  faSearchMinus,
  faSearchPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMediaQuery } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addDays } from "date-fns";
import { useTranslation } from "react-i18next";
import { zhCN, es, fr } from "date-fns/locale";
import { baseUrl } from "../data/baseUrl";
import axios from "axios";
const BookingWidget = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const [viewBooking, setViewBooking] = useState(false);

  const [view, setView] = useState(false);
  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const today = new Date();
  const [arrivalDate, setArrivalDate] = useState(today);
  const [departureDate, setDepartureDate] = useState(addDays(today, 1));
  const [promoCode, setPromoCode] = useState("");
  const [adults, setAdults] = useState(2);
  const handleAdultsChange = (event) => {
    setAdults(Number(event.target.value)); // Convert the value to a number and update the state
  };
  // const [children, setChildren] = useState(0);
  // const [childAges, setChildAges] = useState([]);
  // Calculate maximum children based on adults
  // const maxChildren = adults >= 2 ? 4 - adults : adults === 1 && 3 - adults;
  // useEffect(() => {
  //   if (children > maxChildren) {
  //     setChildren(maxChildren);
  //     setChildAges(childAges.slice(0, maxChildren));
  //   }
  // }, [adults, maxChildren, childAges, children]);
  // Handle the number of children change
  // const handleChildrenChange = (value) => {
  //   const newValue = Math.max(0, Math.min(maxChildren, Number(value)));
  //   setChildren(newValue);

  //   if (newValue > childAges.length) {
  //     setChildAges([...childAges, ""]);
  //   } else if (newValue < childAges.length) {
  //     setChildAges(childAges.slice(0, newValue));
  //   }
  // };

  // Handle age change for a specific child
  // const handleAgeChange = (index, age) => {
  //   const newAges = [...childAges];
  //   newAges[index] = age;
  //   setChildAges(newAges);
  // };
  const handleArrivalChange = (date) => {
    setArrivalDate(date);
    setDepartureDate(addDays(date, 1));
  };
  const getLocale = () => {
    switch (i18n.language) {
      case "cn":
        return zhCN;
      case "es":
        return es;
      case "fr":
        return fr;
      default:
        return undefined; // Default to English if no match
    }
  };
  const { properties, loading, error } = useSelector(
    (state) => state.properties
  );
  // console.log(properties?.map((prop) => prop.propertyName));
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    try {
      const response = await axios.post(`${baseUrl}/api/Booking`, {
        localeLanguage: properties?.bookingLang,
        currency: "USD",
        propertyName: properties?.propertyName,
        pmsCode: properties?.pmsCode,
        arrival: arrivalDate.toLocaleDateString(),
        departure: departureDate.toLocaleDateString(),
        adults: adults.toString(),
        children: "0",
        promoCode: promoCode,
        // childrenAges1: 0,
        // childrenAges2: 0,
      });
      if (response.data.bookingEngineUrl) {
        window.location.href = response.data.bookingEngineUrl;
      }
    } catch (error) {
      console.error("Failed to submit booking:", error);
      alert("Failed to submit booking");
    }
  };
  return (
    <section class="filters-section alternate">
      <div class="auto-container">
        <div
          className="py-1 mb-4 mt-3"
          style={{
            display: isMobile ? "block" : "none",
            backgroundColor: "#d8b560",
          }}
        >
          <Link
            to="#."
            className=" d-flex justify-content-center align-items-center w-100 px-50 my-3"
            onClick={() => setViewBooking(!viewBooking)}
          >
            <span className="text-light">
              <FontAwesomeIcon
                icon={viewBooking ? faSearchMinus : faSearchPlus}
                className="mr-2"
              />
              {!viewBooking && t("Book Now")}
            </span>
          </Link>
        </div>
        <div
          class="form-box default-form filter-form"
          style={{ display: viewBooking || !isMobile ? "block" : "none" }}
        >
          <form onSubmit={handleSubmit}>
            <div className="row clearfix">
              <div className="form-group col-xl-2 col-lg-2 col-md-12 col-sm-12">
                <div className="field-label">{t("Arrival")}</div>
                <div className="field-inner">
                  <DatePicker
                    selected={arrivalDate}
                    onChange={handleArrivalChange}
                    className="date-picker"
                    id="arrival-date"
                    name="date_in"
                    minDate={new Date()}
                    placeholderText=""
                    locale={getLocale()} // This will set the locale based on the selected language
                    dateFormat="P" // Default format for the selected locale
                  />
                  <label
                    htmlFor="arrival-date"
                    className="icon flaticon-calendar"
                  ></label>
                </div>
              </div>
              <div className="form-group col-xl-2 col-lg-2 col-md-12 col-sm-12">
                <div className="field-label">{t("Departure")}</div>
                <div className="field-inner">
                  <DatePicker
                    selected={departureDate}
                    onChange={(date) => setDepartureDate(date)}
                    className="date-picker"
                    id="depart-date"
                    name="date_out"
                    placeholderText=""
                    minDate={departureDate}
                    locale={getLocale()} // This will set the locale based on the selected language
                    dateFormat="P" // Default format for the selected locale
                  />
                  <label
                    htmlFor="depart-date"
                    className="icon flaticon-calendar"
                  ></label>
                </div>
              </div>
              <div className="form-group col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <div className="field-label">{t("Guests")}</div>
                <div className="field-inner">
                  <div className="check-sel-box">
                    <div
                      className="check-sel-btn"
                      onClick={() => setView(!view)}
                    >
                      <span className="adult-info">
                        {adults} {adults == "1" ? t("Adult") : t("Adults")}.
                      </span>
                    </div>
                    <ul
                      className="check-sel-droplist  "
                      style={{ display: view ? "block" : "none" }}
                    >
                      <li>
                        <div className="sel-title">{t("Select Adults")}:</div>
                        <div className="clearfix">
                          <div className="radio-block adult-block">
                            <input
                              type="radio"
                              name="AdultNo"
                              id="radio-1"
                              value="1"
                              onChange={handleAdultsChange}
                            />
                            <label htmlFor="radio-1">1</label>
                          </div>
                          <div className="radio-block adult-block">
                            <input
                              type="radio"
                              name="AdultNo"
                              id="radio-2"
                              value="2"
                              defaultChecked
                              onChange={handleAdultsChange}
                            />
                            <label htmlFor="radio-2">2</label>
                          </div>
                          <div className="radio-block adult-block">
                            <input
                              type="radio"
                              name="AdultNo"
                              id="radio-3"
                              value="3"
                              onChange={handleAdultsChange}
                            />
                            <label htmlFor="radio-3">3</label>
                          </div>
                          <div className="radio-block adult-block">
                            <input
                              type="radio"
                              name="AdultNo"
                              id="radio-4"
                              value="4"
                              onChange={handleAdultsChange}
                            />
                            <label htmlFor="radio-4">4</label>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="form-group col-xl-2 col-lg-2 col-md-12 col-sm-12">
                <div className="field-label">{t("Promo")}</div>
                <div className="field-inner">
                  <input
                    id="promocode"
                    type="text"
                    name="promocode"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="form-group col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <div className="field-label e-label">&nbsp;</div>
                <div className="field-inner">
                  <button className="theme-btn btn-style-one">
                    <span className="btn-title">{t("Check Availability")}</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <Link
          to="#."
          style={{
            display: isMobile && viewBooking ? "block" : "none",
          }}
          className=" d-flex justify-content-center align-items-center w-100 px-50 "
          onClick={() => setViewBooking(false)}
        >
          <span
            className="text-light text-center"
            style={{
              display: isMobile && viewBooking ? "block" : "none",
              backgroundColor: "#d8b560",
            }}
          >
            <FontAwesomeIcon icon={faAngleUp} className="p-3" />
          </span>
        </Link>
      </div>
    </section>
  );
};

export default BookingWidget;
