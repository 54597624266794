import React, { useEffect, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";
import { fetchOffers } from "../../data/offers/offersSlice";

const Offers = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();

  const language = useSelector((state) => state.language.language);
  const { offers, loading, error } = useSelector((state) => state.offers);
  useEffect(() => {
    dispatch(fetchOffers(language));
  }, [dispatch, language]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#d8b560", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          {" "}
          <Helmet>
            <title>{offers.pageDetails?.pageMetatagTitle}</title>
            <link
              rel="canonical"
              href={`https://www.dharahotels.com/${language}/aswan/benben-hotel/offers`}
            />
            <meta
              name="description"
              content={offers.pageDetails?.pageMetatagDescription}
            />
            <meta
              property="og:title"
              content={offers.pageDetails?.metatagTitleFacebook}
            />
            <meta
              property="og:description"
              content={offers.pageDetails?.metatagDescriptionFacebook}
            />
            <meta
              name="twitter:title"
              content={offers.pageDetails?.metatagTitleTwitter}
            />
            <meta
              name="twitter:description"
              content={offers.pageDetails?.metatagDescriptionTwitter}
            />

            <meta name="robots" content="index, follow" />

            <style>
              {` .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }`}
            </style>

            <style>
              {`   
  .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }
 

 
   .starting-from {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
        border: 0px solid #000 !important;
        display: -webkit-flex !important;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        font-size: 13px !important;
        -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
        margin: 0 auto !important;
        max-width: 1000px !important;
        padding: 1px !important;
    }

    .starting-from--txt {
        color: #21acb7;
    }

    .starting-from .starting-from--price {
        font-size: 22px !important;
        font-weight: 600;
        color: #d8b560 !important;
    }`}
            </style>
          </Helmet>
          <section className="page-banner">
            <div
              className="image-layer"
              style={{
                backgroundImage: `url(${
                  isMobile
                    ? offers.pageDetails?.pageBannerMobile
                    : isTablet
                    ? offers.pageDetails?.pageBannerTablet
                    : offers.pageDetails?.pageBannerPC
                })`,
              }}
            ></div>
            <div className="banner-bottom-pattern"></div>

            <div className="banner-inner">
              <div className="auto-container">
                <div className="inner-container clearfix centered">
                  <h1>{offers.pageDetails?.pageTitle}</h1>
                  <div className="page-nav">
                    <ul className="bread-crumb clearfix">
                      <li>
                        <Link to="/">{t("Home")}</Link>
                      </li>
                      <li className="active">
                        {offers.pageDetails?.pageTitle}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="rooms-section rooms-list">
            <div className="auto-container">
              <div className="rooms-box">
                {offers.offers?.map((offer) => (
                  <div key={offer.offerid} className="room-block-four">
                    <div className="inner-box">
                      <div className="image-box">
                        <div
                          className="image-layer"
                          style={{
                            backgroundImage: `url(${offer.offerPhoto})`,
                          }}
                        ></div>
                        <Link className="overlink" to={offer.offerUrl}></Link>
                      </div>

                      <div className="content-box">
                        <h4 style={{ fontSize: "40px" }}>
                          <Link to={offer.offerUrl}>{offer.offerName}</Link>{" "}
                        </h4>

                        <div
                          className="text"
                          dangerouslySetInnerHTML={{
                            __html: offer.offerDescription,
                          }}
                        />

                        <div
                          className="link-row clearfix"
                          style={{ paddingTop: "50px" }}
                        >
                          <div className="link-box" style={{ float: "right" }}>
                            <Link
                              to={offer.offerUrl}
                              className="theme-btn btn-style-one"
                            >
                              <span className="btn-title">{t("Explore")}</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Offers;
