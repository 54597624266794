import React, { Suspense, useEffect } from "react";
// import styles from "./css/terms-and-conditions.module.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
// import { fetchPrivacy } from "../../data/privacy-policy/privacySlice";

const Covid19 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);

  // const { privacy, loading, error } = useSelector((state) => state.privacy);
  // useEffect(() => {
  //   dispatch(fetchPrivacy(language));
  // }, [dispatch, language]);
  return (
    // <>
    //   {loading ? (
    //     <Loader />
    //   ) : error ? (
    //     <section
    //       style={{ backgroundColor: "#e60000", padding: "200px 0 100px" }}
    //     >
    //       <h1 className="text-center text-light">{error}</h1>
    //     </section>
    //   ) : (
    <>
      <Helmet>
        <title>{"privacy policy"}</title>
      </Helmet>

      <section class="page-banner">
        <div
          class="image-layer"
          style={{ backgroundImage: `url(images/uploads/banner-gallery.jpg)` }}
        ></div>
        <div class="banner-bottom-pattern"></div>

        <div class="banner-inner">
          <div class="auto-container">
            <div class="inner-container clearfix centered">
              <h1>Privacy Policy</h1>
              <div class="page-nav">
                <ul class="bread-crumb clearfix">
                  <li>
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li class="active">Privacy Policy</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        class="sidebar-page-container blog-single"
        style={{ paddingTop: "1px" }}
      >
        <div class="auto-container">
          <div class="row clearfix">
            <div class="content-side col-lg-12 col-md-12 col-sm-12">
              <div class="blog-content">
                <div class="post-details">
                  <div class="inner-box">
                    <div class="lower-box">
                      <h3>Covid 19</h3>
                      <div
                        class="text"
                        style={{
                          color: "#000",
                          lineHeight: "2em",
                          textAlign: "justify",
                        }}
                      >
                        <p>/ / / / /</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* </>
      )} */}
    </>
  );
};
export default Covid19;
