import React, { useEffect, useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { baseUrl } from "../../data/baseUrl";
import { Spinner } from "react-bootstrap";
import Loader from "../../Loader";
import { fetchFacilityDetails } from "../../data/facilities/facilityDetailsSlice";
import { Alert, Snackbar, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const ActivitiesDetails = () => {
  const { facilityUrl } = useParams();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();

  const language = useSelector((state) => state.language.language);
  const { facilityDetails, loading, error } = useSelector(
    (state) => state.facilityDetails
  );
  console.log(facilityUrl, language);
  useEffect(() => {
    dispatch(fetchFacilityDetails({ language, facilityUrl }));
  }, [dispatch, language, facilityUrl]);
  const carouselRef = useRef(null);

  const [formData, setFormData] = useState({
    customerName: "",
    customerEmail: "",
    customerPhone: "",
    customermessage: "",
  });
  const [loadingFormData, setLoadingFormData] = useState(false);
  const [errorFormDate, setErrorFormDate] = useState("");
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleCaptchaChange = (token) => {
  //   setRecaptchaToken(token);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!recaptchaToken) {
    //   setErrorFormDate("Please complete the reCAPTCHA.");
    //   setOpen(true);
    //   return;
    // }

    setLoadingFormData(true);
    setErrorFormDate("");
    setSuccess(false);

    try {
      const response = await fetch(
        `${baseUrl}/api/WSRequests/api/requests/requestActivity`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            txt_ActivityId: facilityDetails?.facilityID ?? 0,
            txt_ActivityName: facilityDetails.facilityName,
            txt_CustomerName: formData.customerName,
            txt_CustomerEmail: formData.customerEmail,
            txt_CustomerMobile: formData.customerPhone,
            txt_CustomerMessage: formData.customermessage,

            // namehoney: formData.namehoney,
            // emailhoney: formData.emailhoney,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // const responseText = await response.text();
      // let result;
      // try {
      //   result = JSON.parse(responseText);
      // } catch (e) {
      //   throw new Error("Failed to parse response as JSON");
      // }

      setSuccess(true);
      setOpen(true);
    } catch (error) {
      console.error("Error:", error);
      setErrorFormDate(error.message); // Ensure the error is a string
      setOpen(true);
      setSuccess(false);
    } finally {
      setLoadingFormData(false);
      setFormData({
        customerName: "",
        customerEmail: "",
        customerPhone: "",
        customermessage: "",
      });
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#d8b560", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          {" "}
          <Helmet>
            <title>{facilityDetails?.metatagTitle}</title>
            <link
              rel="canonical"
              href={`https://www.dharahotels.com/${language}/aswan/benben-hotel/activities/${facilityUrl}`}
            />
            <meta
              name="description"
              content={facilityDetails?.metatagDescription}
            />
            <meta
              property="og:title"
              content={facilityDetails?.metatagTitleFacebook}
            />
            <meta
              property="og:description"
              content={facilityDetails?.metatagDescriptionFacebook}
            />
            <meta
              name="twitter:title"
              content={facilityDetails?.metatagTitleTwitter}
            />
            <meta
              name="twitter:description"
              content={facilityDetails?.metatagDescriptionTwitter}
            />

            <meta name="robots" content="index, follow" />

            <style>
              {` .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }`}
            </style>

            <style>
              {`   
  .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }
 

 
   .starting-from {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
        border: 0px solid #000 !important;
        display: -webkit-flex !important;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        font-size: 13px !important;
        -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
        margin: 0 auto !important;
        max-width: 1000px !important;
        padding: 1px !important;
    }

    .starting-from--txt {
        color: #21acb7;
    }

    .starting-from .starting-from--price {
        font-size: 22px !important;
        font-weight: 600;
        color: #d8b560 !important;
    }`}
            </style>
          </Helmet>
          <section className="page-banner ext-banner">
            <div
              className="image-layer"
              style={{
                backgroundImage: `url(${
                  isMobile
                    ? facilityDetails?.facilityBannerMobile
                    : isTablet
                    ? facilityDetails?.facilityBannerTablet
                    : facilityDetails?.facilityBanner
                })`,
              }}
            ></div>
            <div className="banner-bottom-pattern"></div>

            <div className="banner-inner">
              <div className="auto-container">
                <div className="inner-container clearfix"></div>
              </div>
            </div>
          </section>
          <section className="room-single">
            <div className="circles-two">
              <div className="c-1"></div>
              <div className="c-2"></div>
            </div>
            <span></span>
            <span></span>
            <div className="auto-container">
              <div
                className="upper-box wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="upper-inner">
                  <h2>{facilityDetails?.facilityName}</h2>
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: facilityDetails?.facilityDetails,
                    }}
                  />
                  {facilityUrl === "spa" && (
                    <div className="link-box">
                      <a
                        href="BenbenSpaBrochure.pdf"
                        className="theme-btn btn-style-one"
                      >
                        <span className="btn-title">
                          {t("Download Brochure")}
                        </span>
                      </a>
                    </div>
                  )}
                </div>
              </div>

              <div className="lower-box">
                <span className="dotted-pattern dotted-pattern-3"></span>
                <div className="row clearfix">
                  {facilityDetails.facilityGallery?.map((images, index) => (
                    <div
                      className="image-block col-lg-6 col-md-6 col-sm-12"
                      key={index}
                    >
                      <figure className="image">
                        <a
                          href={images.photoFile}
                          className="overlink lightbox-image"
                          data-fancybox="gallery-facilityGallery"
                        >
                          {" "}
                          <img src={images.photoFile} alt="" />
                        </a>
                      </figure>
                    </div>
                  ))}
                </div>
              </div>

              <div className="details-box ">
                <div className="details-inner">
                  <div className="comments-title ">
                    <h4>{t("Request")}</h4>
                  </div>
                  <div
                    className="row"
                    style={{
                      color: "#000",
                      fontSize: "15px",
                      lineHeight: "24px",
                    }}
                  >
                    <div className="default-form comment-form">
                      <form id="contactform" onSubmit={handleSubmit}>
                        <label
                          className="ohnohoney"
                          htmlFor="namehoney"
                        ></label>
                        <input
                          tabIndex="-1"
                          hidden
                          className="ohnohoney"
                          autoComplete="off"
                          type="text"
                          id="namehoney"
                          name="namehoney"
                          placeholder="Your name here"
                          value={formData.namehoney}
                          onChange={handleInputChange}
                        />
                        <label
                          className="ohnohoney"
                          htmlFor="emailhoney"
                        ></label>
                        <input
                          hidden
                          tabIndex="-1"
                          className="ohnohoney"
                          autoComplete="off"
                          type="email"
                          id="emailhoney"
                          name="emailhoney"
                          placeholder="Your e-mail here"
                          value={formData.emailhoney}
                          onChange={handleInputChange}
                        />
                        <div className="row clearfix">
                          <div className="col-md-6 col-sm-12 form-group">
                            <input
                              type="text"
                              name="customerName"
                              placeholder={t("Name")}
                              required
                              value={formData.customerName}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-md-6 col-sm-12 form-group">
                            <input
                              type="email"
                              placeholder={t("Email")}
                              name="customerEmail"
                              required
                              value={formData.customerEmail}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-md-6 col-sm-12 form-group">
                            <input
                              type="text"
                              placeholder={t("Mobile")}
                              name="customerPhone"
                              required
                              value={formData.customerPhone}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-md-12 col-sm-12 form-group">
                            <textarea
                              placeholder={t(
                                "Kindly let us know how we can assist you?"
                              )}
                              name="customermessage"
                              value={formData.customermessage}
                              onChange={handleInputChange}
                            ></textarea>
                          </div>
                          <div className="col-md-12 col-sm-12 form-group">
                            <button
                              type="submit"
                              className="theme-btn btn-style-one"
                            >
                              <span className="btn-title">
                                {" "}
                                {loadingFormData ? (
                                  <>
                                    <Spinner
                                      animation="border"
                                      variant="light"
                                      size="sm"
                                    />{" "}
                                    {t("Loading")}...
                                  </>
                                ) : (
                                  t("Send")
                                )}
                              </span>
                            </button>
                          </div>
                        </div>
                      </form>{" "}
                      {!loadingFormData && success && (
                        <Snackbar
                          open={open}
                          autoHideDuration={6000}
                          onClose={handleClose}
                        >
                          <Alert
                            onClose={handleClose}
                            severity="success"
                            variant="filled"
                            sx={{ width: "100%" }}
                          >
                            {t("Your Request Sent Successfully")}{" "}
                          </Alert>
                        </Snackbar>
                      )}{" "}
                      {errorFormDate && (
                        <Snackbar
                          open={open}
                          autoHideDuration={4000}
                          onClose={handleClose}
                        >
                          <Alert
                            onClose={handleClose}
                            severity="danger"
                            variant="filled"
                            sx={{ width: "100%" }}
                          >
                            {errorFormDate}
                          </Alert>
                        </Snackbar>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="rooms-section-two"
            style={{ padding: "100px 0px 80px !important" }}
          >
            <div className="outer-container">
              <div className="auto-container">
                <div className="sec-title centered">
                  <h2>{t("Other Activities")}</h2>
                </div>

                <div className="carousel-box">
                  <OwlCarousel
                    className="rooms-carousel owl-theme owl-carousel"
                    items={2}
                    loop
                    margin={10}
                    nav
                    responsive={{
                      0: {
                        items: 1, // 1 item for screens up to 600px
                      },
                      600: {
                        items: 1, // 1 item for screens from 600px to 1000px
                      },
                      1000: {
                        items: 2, // 2 items for screens over 1000px
                      },
                    }}
                  >
                    {facilityDetails?.otherFacility?.map((facility, index) => (
                      <div key={index} className="room-block-two">
                        <div className="inner-box">
                          <div className="image-box">
                            <figure className="image">
                              <Link
                                to={`${
                                  facility.isMoreDetails
                                    ? `/activities/${facility.facilityUrl}`
                                    : `https://www.kemetale.com/sailing`
                                }`}
                                target={facility.isMoreDetails ? "" : "_blank"}
                              >
                                <img
                                  src={facility.facilityPhotoHome}
                                  alt={facility.facilityName}
                                  title={facility.facilityName}
                                />
                              </Link>
                            </figure>
                          </div>
                          <div className="lower-box">
                            <h4>
                              <Link
                                to={`${
                                  facility.isMoreDetails
                                    ? `/activities/${facility.facilityUrl}`
                                    : `https://www.kemetale.com/sailing`
                                }`}
                                target={facility.isMoreDetails ? "" : "_blank"}
                              >
                                {facility.facilityName}
                              </Link>
                            </h4>
                            <div
                              className="text"
                              style={{
                                margin: "0 0 10px",
                                color: "#000 !important",
                              }}
                            >
                              <p
                                className="line-clamp3"
                                dangerouslySetInnerHTML={{
                                  __html: facility.facilitySummery,
                                }}
                              />
                            </div>
                            <div
                              className="link-box"
                              style={{ marginTop: "20px" }}
                            >
                              <Link
                                to={`${
                                  facility.isMoreDetails
                                    ? `/activities/${facility.facilityUrl}`
                                    : `https://www.kemetale.com/sailing`
                                }`}
                                target={facility.isMoreDetails ? "" : "_blank"}
                                className="theme-btn btn-style-three"
                              >
                                <span className="btn-title">
                                  {t("More Details")}
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ActivitiesDetails;
