import React, { useEffect, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { fetchDining } from "../../data/dining/diningSlice";
import Loader from "../../Loader";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages } from "@fortawesome/free-regular-svg-icons";
import Fancybox from "../../components/fancybox-library/fancybox";
const Dining = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();

  const language = useSelector((state) => state.language.language);
  const { dining, loading, error } = useSelector((state) => state.dining);
  useEffect(() => {
    dispatch(fetchDining(language));
  }, [dispatch, language]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#d8b560", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{dining.pageDetails?.pageMetatagTitle}</title>
            <link
              rel="canonical"
              href={`https://devreact.titegypt.org/${language}/aswan/benben-hotel/dining`}
            />
            <meta name="robots" content="index, follow" />
            <meta
              name="description"
              content={dining.pageDetails?.pageMetatagDescription}
            />
            <meta
              property="og:title"
              content={dining.pageDetails?.metatagTitleFacebook}
            />
            <meta
              property="og:description"
              content={dining.pageDetails?.metatagDescriptionFacebook}
            />
            <meta
              name="twitter:title"
              content={dining.pageDetails?.metatagTitleTwitter}
            />
            <meta
              name="twitter:description"
              content={dining.pageDetails?.metatagDescriptionTwitter}
            />
            <style>
              {` .banner-carousel .slide-item:before {
                opacity: var(--opacity-color) !important;
            }`}
            </style>

            <style>
              {`  
            .banner-carousel .slide-item:before {
                opacity: var(--opacity-color) !important;
            }
       


       
            .starting-from {
                -webkit-box-align: center !important;
                -webkit-align-items: center !important;
                -ms-flex-align: center !important;
                align-items: center !important;
                border: 0px solid #000 !important;
                display: -webkit-flex !important;
                display: -webkit-box !important;
                display: -ms-flexbox !important;
                display: flex !important;
                font-size: 13px !important;
                -webkit-justify-content: space-around !important;
                -ms-flex-pack: distribute !important;
                justify-content: space-around !important;
                margin: 0 auto !important;
                max-width: 1000px !important;
                padding: 1px !important;
            }

            .starting-from--txt {
                color: #21acb7;
            }

            .starting-from .starting-from--price {
                font-size: 22px !important;
                font-weight: 600;
                color: #d8b560 !important;
            }
         `}
            </style>
          </Helmet>
          <section class="page-banner">
            <div
              class="image-layer"
              style={{
                backgroundImage: `url(${
                  isMobile
                    ? dining.pageDetails?.pageBannerMobile
                    : isTablet
                    ? dining.pageDetails?.pageBannerTablet
                    : dining.pageDetails?.pageBannerPC
                })`,
              }}
            ></div>
            <div class="banner-bottom-pattern"></div>

            <div class="banner-inner">
              <div class="auto-container">
                <div class="inner-container clearfix centered">
                  <h1>{dining.pageDetails?.pageTitle}</h1>
                  <div class="page-nav">
                    <ul class="bread-crumb clearfix">
                      <li>
                        <Link to="/">{t("Home")}</Link>
                      </li>
                      <li class="active">{dining.pageDetails?.pageTitle}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="rooms-section rooms-list">
            <div className="auto-container">
              <div className="rooms-box">
                {dining.restauransList?.map((res, i) => (
                  <div
                    className="room-block-four "
                    key={dining.restaurantID}
                    id={res.restaurantUrl}
                  >
                    <div className="inner-box">
                      <div className="image-box">
                        {" "}
                        <figure className="image">
                          <img
                            src={res.restaurantPhoto}
                            alt={res.restaurantName}
                            title={res.restaurantName}
                          />
                        </figure>
                        <div
                          className="image-layer"
                          style={{
                            backgroundImage: `url(${res.restaurantPhoto})`,
                          }}
                        >
                          {" "}
                          <FontAwesomeIcon
                            icon={faImages}
                            className="text-light p-2 "
                            style={{
                              fontSize: "20px",
                              textShadow: "0 0 2px #000",
                            }}
                          />
                        </div>
                        {res.restaurantPageGalleries?.map((image, index) => (
                          <a
                            key={index}
                            className={`${
                              index === 0
                                ? "overlink lightbox-image"
                                : "lightbox-image"
                            }`}
                            href={image.photoFile}
                            rel=""
                            data-fancybox={`gallery-${i}`}
                          ></a>
                        ))}
                      </div>

                      <div
                        className="content-box"
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                      >
                        <h4 style={{ fontSize: "40px" }}>
                          {res.restaurantName}
                        </h4>
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{
                            __html: res.restaurantDetails,
                          }}
                        />
                        {/* <ul className="titsolution-Dining-UL mb-2">
                          <li>
                            You can download our Breakfast Menu{" "}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="Breakfast.pdf"
                            >
                              Here
                            </a>
                          </li>
                          <li>
                            <span className="titsolution-HighlightsTitle">
                              You can download our Dining All Day Menu{" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="DiningAllDay.pdf"
                              >
                                Here
                              </a>
                            </span>
                          </li>
                        </ul>{" "} */}
                        {res.restaurantCuisine ||
                        res.restaurantOpenFor ||
                        res.restaurantMenu ||
                        res.openinghours ||
                        res.restaurantNote ? (
                          <h3
                            className="titsolution-diningBoxSubTitle"
                            style={{ paddingTop: "1px" }}
                          >
                            <i
                              className="fa fa-check-square-o"
                              aria-hidden="true"
                            ></i>{" "}
                            {t("Highlights")}
                          </h3>
                        ) : (
                          ""
                        )}
                        <ul className="titsolution-Dining-UL">
                          {res.restaurantCuisine && (
                            <li>
                              <span className="titsolution-HighlightsTitle">
                                {t("Cuisine")} :
                              </span>{" "}
                              {res.restaurantCuisine}
                            </li>
                          )}
                          {res.restaurantOpenFor && (
                            <li>
                              <span className="titsolution-HighlightsTitle">
                                {t("Open for")} :
                              </span>{" "}
                              {res.restaurantOpenFor}
                            </li>
                          )}
                          {res.restaurantMenu && (
                            <li>
                              <span className="titsolution-HighlightsTitle">
                                {t("Menu")} :
                              </span>{" "}
                              {res.restaurantMenu}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}

                {/* <div
                  className="room-block-four wow fadeInUp"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div className="inner-box">
                    <div className="image-box">
                      <figure className="image">
                        <img
                          src="images/uploads/benben-hotel-philae-bar-terrace.jpg"
                          alt="Philae Bar & Terrace"
                          title="Philae Bar & Terrace"
                        />
                      </figure>
                      <div
                        className="image-layer"
                        style={{
                          backgroundImage:
                            "url(images/uploads/benben-hotel-philae-bar-terrace.jpg)",
                        }}
                      ></div>
                      <a
                        className="overlink lightbox-image"
                        data-fancybox="gallery-1107"
                        href="images/uploads/benben-hotel-philae-bar-terrace-20224224317492.jpg"
                      ></a>
                      <a
                        className="lightbox-image"
                        data-fancybox="gallery-1107"
                        href="images/uploads/benben-hotel-philae-bar-terrace-20224224321856.jpg"
                      ></a>
                    </div>

                    <div
                      className="content-box"
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      <h4 style={{ fontSize: "40px" }}>Philae Bar & Terrace</h4>
                      <div className="text">
                        Encapsulating the freedom that defines the BenBen Hotel
                        experience, our terrace offers you free rein, an
                        opportunity to do what you want, whenever you want. Take
                        in the sights, read a book, or simply sit and revel in
                        the BenBen Rock’s transcendent energy and the Nile’s
                        azure blue waters, or spend the night playing games with
                        friends and family - or even fellow guests. Have you can
                        enjoy all our virgin beverages.
                      </div>
                      <ul className="titsolution-Dining-UL">
                        <li>
                          <span className="titsolution-HighlightsTitle">
                            You can download our beverage menu{" "}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="Beverage.pdf"
                            >
                              Here
                            </a>
                          </span>
                        </li>
                      </ul>
                      <br />
                    </div>
                  </div>
                </div>

                <div
                  className="room-block-four wow fadeInUp"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div className="inner-box">
                    <div className="image-box">
                      <figure className="image">
                        <img
                          src="images/uploads/benben-hotel-in-room-dining-room-service.jpg"
                          alt="In-room Dining/Room Service"
                          title="In-room Dining/Room Service"
                        />
                      </figure>
                      <div
                        className="image-layer"
                        style={{
                          backgroundImage:
                            "url(images/uploads/benben-hotel-in-room-dining-room-service.jpg)",
                        }}
                      ></div>
                      <a
                        className="overlink lightbox-image"
                        data-fancybox="gallery-1108"
                        href="images/uploads/benben-hotel-in-room-dining-room-service-2022422464777.jpg"
                      ></a>
                      <a
                        className="lightbox-image"
                        data-fancybox="gallery-1108"
                        href="images/uploads/benben-hotel-in-room-dining-room-service-2022422469346.jpg"
                      ></a>
                      <a
                        className="lightbox-image"
                        data-fancybox="gallery-1108"
                        href="images/uploads/benben-hotel-in-room-dining-room-service-20224224613574.jpg"
                      ></a>
                    </div>

                    <div
                      className="content-box"
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      <h4 style={{ fontSize: "40px" }}>
                        In-room Dining/Room Service
                      </h4>
                      <div className="text">
                        If you’re inclined to stay in and enjoy a private meal,
                        we bring you the luscious dishes you crave right at your
                        doorstep for you to devour your special treat.
                      </div>
                      <ul className="titsolution-Dining-UL">
                        <li>
                          <span className="titsolution-HighlightsTitle">
                            You can download our In-Room Dining{" "}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="InRoomDining.pdf"
                            >
                              Here
                            </a>
                          </span>
                        </li>
                        <li>
                          <span className="titsolution-HighlightsTitle">
                            You can download our Set Menu{" "}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="SetMenu.pdf"
                            >
                              Here
                            </a>
                          </span>
                        </li>
                      </ul>
                      <br />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Dining;
