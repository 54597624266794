// src/index.js
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "./Responsive.css";
import { store } from "./data/store";
import { Provider } from "react-redux";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import InitialPage from "./InitialPage";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

const MainApp = (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<InitialPage />}>
        <App />
      </Suspense>
    </I18nextProvider>
  </Provider>
);

// Hydrate with the initial loading page
if (process.env.REACT_SNAP) {
  root.render(MainApp);
} else {
  root.render(MainApp);
}
