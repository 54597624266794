import React, { Suspense, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet";

import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { fetchHomePageData } from "../data/homeSlice";
import BookingWidget from "../components/BookingWidget";
import { useTranslation } from "react-i18next";
import Loader from "./../Loader";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { fetchHotels } from "../data/hotelsSlice";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const Home = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");

  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);

  const { hotels, loading, error } = useSelector((state) => state.hotels);

  useEffect(() => {
    dispatch(fetchHotels(language));
  }, [dispatch, language]);

  const carouselRef = useRef(null);

  const prevSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  const nextSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#d8b560", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{hotels?.seO_MetatagTitle}</title>
            <link
              rel="canonical"
              href={`https://dharahotels.com/${language}/aswan/benben-hotel/`}
            />
            <meta name="description" content={hotels?.seO_MetatagDescription} />
            <meta name="title" content={hotels?.seO_MetatagTitle} />
            {/* Open Graph / Facebook Meta Tags */}
            <meta
              property="og:title"
              content={hotels?.seO_MetatagTitleFacebook}
            />
            <meta
              property="og:description"
              content={hotels?.seO_MetatagDescriptionFacebook}
            />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="{https://dharahotels.com}" />{" "}
            {/* Twitter Meta Tags */}
            <meta
              name="twitter:title"
              content={hotels?.seO_MetatagTitleTwitter}
            />
            <meta
              name="twitter:description"
              content={hotels?.seO_MetatagDescriptionTwitter}
            />
            <link
              rel="stylesheet"
              property="stylesheet"
              href="widgets/app.css"
            />
            <script type="text/javascript" src="widgets/app.js" />
            <style>
              {` .banner-carousel .slide-item:before {
                opacity: var(--opacity-color) !important;
            }`}
            </style>
            <style>
              {`  
            .banner-carousel .slide-item:before {
                opacity: var(--opacity-color) !important;
            }
       


       
            .starting-from {
                -webkit-box-align: center !important;
                -webkit-align-items: center !important;
                -ms-flex-align: center !important;
                align-items: center !important;
                border: 0px solid #000 !important;
                display: -webkit-flex !important;
                display: -webkit-box !important;
                display: -ms-flexbox !important;
                display: flex !important;
                font-size: 13px !important;
                -webkit-justify-content: space-around !important;
                -ms-flex-pack: distribute !important;
                justify-content: space-around !important;
                margin: 0 auto !important;
                max-width: 1000px !important;
                padding: 1px !important;
            }

            .starting-from--txt {
                color: #21acb7;
            }

            .starting-from .starting-from--price {
                font-size: 22px !important;
                font-weight: 600;
                color: #d8b560 !important;
            }
         `}
            </style>
          </Helmet>
          {/*Banner */}
          <section className="banner-section banner-two">
            <OwlCarousel
              className="banner-carousel owl-theme"
              items={1} // Show only one item at a time
              nav={false} // Enable navigation
              loop // Enable looping of slides
              margin={10}
              navText={[
                "<div class='nav-btn prev-slide'></div>",
                "<div class='nav-btn next-slide'></div>",
              ]} // Custom navigation
              dots // Enable dots
              dotClass="owl-dot" // Custom dot class
              dotsClass="owl-dots" // Custom dots container class
              autoplay
              autoplayTimeout={5000}
              smartSpeed={7000}
              animateIn="auto"
              autoplayHoverPause={false}
            >
              {hotels?.sliders?.map((slider, index) => (
                <div
                  key={index}
                  className="slide-item"
                  style={{ "--opacity-color": 0 }}
                >
                  <div
                    className="image-layer"
                    style={{
                      backgroundImage: `url(${
                        isMobile
                          ? slider.sliderPhotoMobile
                          : isTablet
                          ? slider.sliderPhotoTablet
                          : slider.sliderPhoto
                      })`,
                    }}
                  ></div>
                  <div className="auto-container">
                    <div className="content-box">
                      <div className="content">
                        <div className="inner">
                          <h1 style={{ color: "#ffffff !important" }}>
                            {slider.sliderSubText}
                          </h1>
                          <div
                            className="text"
                            style={{ color: "#ffffff !important" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </section>
          <BookingWidget />
          {/*Featured Section*/}
          <section class="featured-section-two">
            <div class="pattern-bottom"></div>
            <span></span>
            <span class="tri-pattern tri-pattern-4"></span>
            <div class="auto-container">
              <div class="row clearfix">
                <div class="text-column col-xl-4 col-lg-12 col-sm-12">
                  <div class="inner">
                    <div class="sec-title">
                      <h2>{hotels?.aboutUs_MainTitle}</h2>
                    </div>
                    <div class="text">{hotels?.aboutUs_Intro}</div>
                    <div class="link-box" style={{ marginTop: "40px" }}>
                      <Link
                        to={hotels?.aboutUs_ButtonLinkURL}
                        class="theme-btn btn-style-one"
                      >
                        <span class="btn-title">
                          {hotels?.aboutUs_ButtonText}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div class="image-column col-xl-8 col-lg-12 col-sm-12">
                  <div class="inner">
                    <span></span>
                    <div class="row clearfix">
                      <div
                        class="image-block col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
                        data-wow-delay="0ms"
                        data-wow-duration="1500ms"
                      >
                        <div class="image-box">
                          <figure class="image">
                            <Link to="#.">
                              <img src={hotels?.aboutUs_Photo1} alt="" />
                            </Link>
                          </figure>
                        </div>
                      </div>
                      <div class="image-block col-lg-6 col-md-6 col-sm-12 ">
                        <div class="image-box">
                          <figure class="image">
                            <img src={hotels?.aboutUs_Photo2} alt="" title="" />
                          </figure>
                          <div class="cap">
                            <span
                              class="txt"
                              dangerouslySetInnerHTML={{
                                __html: hotels?.aboutUs_Photo2_Text,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Rooms Section  */}
          <section
            className="rooms-section-two"
            style={{ padding: "100px 0px 80px" }}
          >
            <div className="outer-container">
              <div className="auto-container">
                <div className="sec-title centered">
                  <h2>{hotels?.accommodation_Title}</h2>
                  <div
                    className="lower-text"
                    dangerouslySetInnerHTML={{
                      __html: hotels?.accommodation_Intro,
                    }}
                  />
                </div>
                <div className="carousel-box">
                  <OwlCarousel
                    className="rooms-carousel owl-theme"
                    items={2}
                    loop
                    margin={10}
                    nav
                    responsive={{
                      0: {
                        items: 1, // 1 item for screens up to 600px
                      },
                      600: {
                        items: 1, // 1 item for screens from 600px to 1000px
                      },
                      1000: {
                        items: 2, // 2 items for screens over 1000px
                      },
                    }}
                  >
                    {hotels.hotelRooms?.map((room, index) => (
                      <div key={index} className="room-block-two">
                        <div className="inner-box">
                          <div className="image-box">
                            <figure className="image">
                              <Link
                                to={`/accommodation/${room.roomTypeCategoryName.toLowerCase()}/${
                                  room.roomUrl
                                }`}
                              >
                                <img
                                  src={room.roomPhoto}
                                  alt={room.roomName}
                                  title={room.roomName}
                                />
                              </Link>
                            </figure>
                          </div>
                          <div className="lower-box">
                            <h4>
                              <Link
                                to={`/accommodation/${room.roomTypeCategoryName.toLowerCase()}/${
                                  room.roomUrl
                                }`}
                              >
                                {room.roomName}
                              </Link>
                            </h4>
                            <div
                              className="text"
                              style={{ margin: "0 0 10px", color: "#000" }}
                            >
                              <p
                                className="line-clamp2"
                                dangerouslySetInnerHTML={{
                                  __html: room.roomSummery,
                                }}
                              />
                            </div>
                            <ul
                              className="info clearfix"
                              style={{ paddingLeft: "10px" }}
                            >
                              {room.roomView && (
                                <li>
                                  <span style={{ color: "#21acb7" }}>
                                    <i
                                      className="fa fa-binoculars"
                                      aria-hidden="true"
                                    ></i>
                                    <span style={{ paddingLeft: "5px" }}>
                                      {t("View")}:
                                    </span>
                                  </span>{" "}
                                  {room.roomView}
                                </li>
                              )}
                              {room.roomBed && (
                                <li>
                                  <span style={{ color: "#21acb7" }}>
                                    <i
                                      className="fa fa-bed"
                                      aria-hidden="true"
                                    ></i>
                                    <span style={{ paddingLeft: "5px" }}>
                                      {t("Bed")}:
                                    </span>
                                  </span>{" "}
                                  {room.roomBed}
                                </li>
                              )}
                              {room.roomSize && (
                                <li>
                                  <span style={{ color: "#21acb7" }}>
                                    <i
                                      className="fa fa-arrows-alt"
                                      aria-hidden="true"
                                    ></i>
                                    <span style={{ paddingLeft: "5px" }}>
                                      {t("Size")}:
                                    </span>
                                  </span>{" "}
                                  {room.roomSize}
                                </li>
                              )}
                              {room.maxOccupancy && (
                                <li>
                                  <span style={{ color: "#21acb7" }}>
                                    <span className="icon flaticon-user-2"></span>
                                    <span style={{ paddingLeft: "5px" }}>
                                      {t("Max Occupancy")}:
                                    </span>
                                  </span>{" "}
                                  {room.maxOccupancy}
                                </li>
                              )}
                            </ul>
                            {room.roomPMSCode && (
                              <>
                                <div
                                  id={`fb-widget-${index}`}
                                  className="fb-widget"
                                  data-fbConfig={index}
                                  style={{ marginTop: "20px" }}
                                ></div>
                                <script
                                  className="fb-widget-config"
                                  data-fbConfig={index}
                                  type="application/json"
                                >
                                  {JSON.stringify({
                                    params: [
                                      {
                                        startingFrom: {
                                          theme: "sf--thm-1",
                                          call2action: "none",
                                          roomRateFiltering: 2,
                                          rateFilter: [],
                                          roomFilter: [room.roomPMSCode],
                                          dayRange: 180,
                                        },
                                        currency: "EGP",
                                        locale: room.bookingLang,
                                        pricesDisplayMode: "normal",
                                        offerComputeRule: "lowerPrice",
                                        maxAdults: 4,
                                        maxChildren: 0,
                                        mainColor: "#b0a06c",
                                        themeDark: false,
                                        openFrontInNewTab: true,
                                        property: room.hotelPMSCode,
                                        title: room.hotelName,
                                        // property: "egasw00001",
                                        // title: "BenBen by Dhara Hotels",
                                        childrenMaxAge: 12,
                                        groupsNames: "",
                                        quicksearch: {
                                          showChildrenAges: false,
                                        },
                                        fbWidget: "StartingFrom",
                                      },
                                    ],
                                    commonParams: {
                                      redirectUrl:
                                        "https://redirect.fastbooking.com/DIRECTORY/dispoprice.phtml",
                                      showPropertiesList: false,
                                      demoMode: false,
                                      allowGroupSelection: false,
                                      groupName: "",
                                    },
                                    _authCode:
                                      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzY29wZXMiOiIuKiIsInByb3BlcnRpZXMiOiJlZ2FzdzAwMDAxIiwiZ3JvdXBzIjoiXiQiLCJmb3IiOiJCYWNrb2ZmaWNlIiwiaWF0IjoxNjQzODQyMDU1LCJqaWQiOiI2MWZhZWRlNzU5ZGJhIn0.EQ6Iyw6Vu82AaNXUfDsA3LqeoTY1Et930P3hVhoipdg",
                                    propertyIndex: 0,
                                    version: "1.46.0",
                                    baseHost: "websdk.fastbooking-services.com",
                                  })}
                                </script>
                              </>
                            )}
                            <div
                              className="link-box"
                              style={{ marginTop: "5px" }}
                            >
                              <Link
                                to={`/accommodation/${room.roomTypeCategoryName.toLowerCase()}/${
                                  room.roomUrl
                                }`}
                                className="theme-btn btn-style-three"
                              >
                                <span className="btn-title">
                                  {t("Learn More")}
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>
          {/* Video Section  */}
          <section class="video-section">
            <div
              class="image-layer"
              style={{
                backgroundImage: `url(${hotels?.wideBanner_Photo})`,
              }}
            ></div>
            <div class="auto-container">
              <div
                class="content-box wow zoomIn"
                data-wow-delay="0ms"
                data-wow-duration="2000ms"
              >
                <h1>{hotels?.wideBanner_Title}</h1>
                <div class="link-box">
                  <Link to="story" class="theme-btn btn-style-one">
                    <span class="btn-title">
                      {hotels?.wideBanner_ButtonText}
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </section>
          {/* Why Choose Us  */}
          <section class="why-us-section">
            <span></span>
            <span class="tri-pattern tri-pattern-5"></span>
            <div class="circles">
              <div class="c-1"></div>
              <div class="c-2"></div>
            </div>
            <div class="auto-container">
              <div class="sec-title centered">
                <h2>{hotels?.whyUs_Title}</h2>
                <div
                  class="lower-text"
                  dangerouslySetInnerHTML={{ __html: hotels?.whyUs_Intro }}
                />
              </div>
              <div class="row clearfix">
                {hotels.hotelWhyUs?.map((item) => (
                  <div
                    class="feature-box col-xl-4 col-lg-6 col-sm-12"
                    key={item.whuUsPosition}
                  >
                    <div class="inner-box">
                      <div class="icon-box">
                        <img
                          src={item.whuUsPhoto}
                          alt={item.whuUsTitle}
                          style={{ paddingBottom: "20px" }}
                        />
                      </div>
                      <h4 style={{ color: "#d8b560" }}>{item.whuUsTitle}</h4>
                      <div
                        class="text"
                        style={{ minHeight: "82px" }}
                        dangerouslySetInnerHTML={{ __html: item.whuUsText }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          {/* Gallery Section  */}
          <section
            className="gallery-section"
            style={{ background: "#edf0f5 !important" }}
          >
            <span></span>
            <span className="tri-pattern tri-pattern-3"></span>
            <div className="auto-container">
              <div className="sec-title">
                <h2>{hotels?.ourGallery_Title}</h2>
                <div
                  className="lower-text"
                  dangerouslySetInnerHTML={{ __html: hotels?.ourGallery_Intro }}
                />
              </div>
            </div>{" "}
            <div className="carousel-box">
              <div
                className="owl-nav"
                style={{ position: "relative", right: "10vw", top: "-13vh" }}
              >
                <button
                  type="button"
                  role="presentation"
                  className="owl-prev"
                  onClick={prevSlide}
                  style={{
                    right: "4vw",
                    position: "absolute",
                    fontSize: "22px",
                    fontWeight: "700",
                    background: "transparent",
                  }}
                >
                  <span
                    className="icon flaticon-left-arrow-1"
                    style={{ color: "rgba(0,0,0,0.30)" }}
                  ></span>
                </button>
                <button
                  type="button"
                  role="presentation"
                  className="owl-next"
                  onClick={nextSlide}
                  style={{
                    right: "2vw",
                    position: "absolute",
                    fontSize: "20px",
                    fontWeight: "700",
                    background: "transparent",
                  }}
                >
                  <span
                    className="icon flaticon-arrows-10"
                    style={{ color: "rgba(0,0,0,0.30)" }}
                  ></span>
                </button>
              </div>
              <OwlCarousel
                className="gallery-carousel owl-theme"
                loop
                margin={0}
                nav={false}
                items={3}
                ref={carouselRef}
                autoplay
                autoplayTimeout={4000}
                smartSpeed={2000}
                animateIn="animate__fadeIn" // Add animation classes for slide in
                animateOut="animate__fadeOut" // Add animation classes for slide out
                responsive={{
                  0: {
                    items: 1, // 1 item for screens up to 600px
                  },
                  600: {
                    items: 1, // 1 item for screens from 600px to 1000px
                  },
                  1000: {
                    items: 3, // 2 items for screens over 1000px
                  },
                }}
              >
                {hotels.hotelHomeGallery?.map((image, index) => (
                  <div className="slide-item" key={index}>
                    <div className="gallery-block">
                      <div className="inner-box">
                        <div className="image">
                          <img
                            src={image.photoFile}
                            alt={`Gallery photoFile ${index}`}
                          />
                        </div>
                        <a
                          href={image.photoFile}
                          className="overlink lightbox-image"
                          data-fancybox="gallery-hotelHomeGallery"
                        >
                          <span className="icon flaticon-cross"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default Home;
