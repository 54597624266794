import React, { useEffect, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";
import { fetchAboutDhara } from "../../data/about/aboutDharaSlice";

// Import Fancybox CSS
const DharaHotels = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();

  const language = useSelector((state) => state.language.language);
  const { aboutDhara, loading, error } = useSelector(
    (state) => state.aboutDhara
  );
  useEffect(() => {
    dispatch(fetchAboutDhara(language));
  }, [dispatch, language]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#d8b560", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          {" "}
          <Helmet>
            <title>{aboutDhara?.metatagTitle}</title>
            <link
              rel="canonical"
              href={`https://www.dharahotels.com/${language}/aswan/benben-hotel/dhara-hotels`}
            />
            <meta name="description" content={aboutDhara?.metatagDescription} />
            <meta
              property="og:title"
              content={aboutDhara?.metatagTitleFacebook}
            />
            <meta
              property="og:description"
              content={aboutDhara?.metatagDescriptionFacebook}
            />
            <meta
              name="twitter:title"
              content={aboutDhara?.metatagTitleTwitter}
            />
            <meta
              name="twitter:description"
              content={aboutDhara?.metatagDescriptionTwitter}
            />

            <meta name="robots" content="index, follow" />

            <style>
              {` .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }`}
            </style>

            <style>
              {`   
  .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }
 

 
   .starting-from {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
        border: 0px solid #000 !important;
        display: -webkit-flex !important;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        font-size: 13px !important;
        -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
        margin: 0 auto !important;
        max-width: 1000px !important;
        padding: 1px !important;
    }

    .starting-from--txt {
        color: #21acb7;
    }

    .starting-from .starting-from--price {
        font-size: 22px !important;
        font-weight: 600;
        color: #d8b560 !important;
    }`}
            </style>
          </Helmet>
          <section class="page-banner">
            <div
              class="image-layer"
              style={{
                backgroundImage: `url(${
                  isMobile
                    ? aboutDhara?.pageBannerMobile
                    : isTablet
                    ? aboutDhara?.pageBannerTablet
                    : aboutDhara?.pageBanner
                })`,
              }}
            ></div>
            <div class="banner-bottom-pattern"></div>

            <div class="banner-inner">
              <div class="auto-container">
                <div class="inner-container clearfix centered">
                  <h1>{aboutDhara?.pageTitle}</h1>
                  <div class="page-nav">
                    <ul class="bread-crumb clearfix">
                      <li>
                        <Link to="/">{t("Home")}</Link>
                      </li>
                      <li class="active">{aboutDhara?.pageTitle}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="sidebar-page-container">
            <div className="circles-two">
              <div></div>
              <div></div>
            </div>
            <span></span>
            <span></span>

            <div className="auto-container">
              <div className="row clearfix">
                {/* Content Side */}
                <div className="content-side col-lg-8 col-md-12 col-sm-12">
                  <div className="blog-content">
                    {/* News Block */}
                    {aboutDhara.aboutDharaItemsList?.map((item, index) => (
                      <div className="news-block-four " key={index}>
                        <div className="inner-box">
                          <div
                            className="lower-box"
                            style={{ padding: "50px 35px 40px !important" }}
                          >
                            <h3>{item.itemTitle}</h3>
                            <div
                              className="text"
                              style={{
                                color: "#000",
                                lineHeight: "2em",
                                textAlign: "justify",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: item.itemDescription,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Sidebar Side */}
                <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                  <aside className="sidebar blog-sidebar">
                    <div
                      className="sidebar-widget archives wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="widget-inner">
                        <div className="sidebar-title">
                          <h4>{t("Accommodation")}</h4>
                          <div className="text">
                            {t("Where beautiful become inimitable")}
                          </div>
                        </div>
                        <ul>
                          {aboutDhara.list_GetRooms?.map((room) => (
                            <li key={room.roomUrl}>
                              <Link
                                to={`/accommodation/${
                                  room.filterBy ?? "rooms"
                                }/${room.roomUrl}`}
                              >
                                {room.roomName}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div className="sidebar-widget gallery">
                      <div className="widget-inner">
                        <div className="sidebar-title">
                          <h4> {t("Popular Gallery")} </h4>
                        </div>
                        <div className="images clearfix">
                          {aboutDhara.list_GetPhotos?.map((image, index) => (
                            <div className="image" key={index}>
                              <a
                                href={image.photoFile}
                                className="lightbox-image"
                                data-fancybox="gallery-list_GetPhotos"
                              >
                                <img src={image.photoFile} alt="" title="" />
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DharaHotels;
