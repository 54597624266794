import React, { useEffect, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";
import { fetchGallery } from "../../data/gallery/gallerySlice";

// Import Fancybox CSS
const Gallery = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();

  const language = useSelector((state) => state.language.language);
  const { gallery, loading, error } = useSelector((state) => state.gallery);
  useEffect(() => {
    dispatch(fetchGallery(language));
  }, [dispatch, language]);

  const [filter, setFilter] = useState("all");

  const filteredImages =
    filter === "all"
      ? gallery?.galleries
      : gallery.galleries?.filter((img) => img.gallerySectionName === filter);
  const groupImagesBySection = (galleries) => {
    const sections = {};

    // Ensure galleries is defined and is an array
    if (galleries && Array.isArray(galleries)) {
      galleries.forEach((image) => {
        if (!sections[image.gallerySectionName]) {
          sections[image.gallerySectionName] = [];
        }
        sections[image.gallerySectionName].push(image);
      });
    }

    return sections;
  };
  const sections = groupImagesBySection(gallery.galleries);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#d8b560", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          {" "}
          <Helmet>
            <title>{gallery.pageDetails?.pageMetatagTitle}</title>
            <link
              rel="canonical"
              href={`https://www.dharahotels.com/en/aswan/benben-hotel/gallery`}
            />
            <meta
              name="description"
              content={gallery.pageDetails?.pageMetatagDescription}
            />
            <meta
              property="og:title"
              content={gallery.pageDetails?.metatagTitleFacebook}
            />
            <meta
              property="og:description"
              content={gallery.pageDetails?.metatagDescriptionFacebook}
            />
            <meta
              name="twitter:title"
              content={gallery.pageDetails?.metatagTitleTwitter}
            />
            <meta
              name="twitter:description"
              content={gallery.pageDetails?.metatagDescriptionTwitter}
            />
            <link
              rel="canonical"
              href={`https://www.dharahotels.com/en/aswan/benben-hotel/accommodation`}
            />
            <meta name="robots" content="index, follow" />

            <style>
              {` .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }`}
            </style>

            <style>
              {`   
  .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }
 

 
   .starting-from {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
        border: 0px solid #000 !important;
        display: -webkit-flex !important;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        font-size: 13px !important;
        -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
        margin: 0 auto !important;
        max-width: 1000px !important;
        padding: 1px !important;
    }

    .starting-from--txt {
        color: #21acb7;
    }

    .starting-from .starting-from--price {
        font-size: 22px !important;
        font-weight: 600;
        color: #d8b560 !important;
    }`}
            </style>
          </Helmet>
          <section class="page-banner">
            <div
              class="image-layer"
              style={{
                backgroundImage: `url(${
                  isMobile
                    ? gallery.pageDetails?.pageBannerMobile
                    : isTablet
                    ? gallery.pageDetails?.pageBannerTablet
                    : gallery.pageDetails?.pageBannerPC
                })`,
              }}
            ></div>
            <div class="banner-bottom-pattern"></div>

            <div class="banner-inner">
              <div class="auto-container">
                <div class="inner-container clearfix centered">
                  <h1>{gallery.pageDetails?.pageTitle}</h1>
                  <div class="page-nav">
                    <ul class="bread-crumb clearfix">
                      <li>
                        <Link to="/">{t("Home")}</Link>
                      </li>
                      <li class="active">{gallery.pageDetails?.pageTitle}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="gallery-section-two">
            <div className="auto-container">
              <div className="mixit-gallery filter-gallery">
                <div className="filters clearfix">
                  <ul className="filter-tabs filter-btns clearfix">
                    <li
                      className={`filter ${filter === "all" ? "active" : ""}`}
                      onClick={() => setFilter("all")}
                    >
                      {t("All")}
                    </li>
                    {Object.entries(sections)?.map(
                      ([sectionName, images], index) => (
                        <li
                          key={index}
                          className={`filter ${
                            filter === sectionName ? "active" : ""
                          }`}
                          onClick={() => setFilter(sectionName)}
                        >
                          {sectionName}
                        </li>
                      )
                    )}
                  </ul>
                </div>

                <div className="filter-list row clearfix">
                  {filteredImages?.map((img, index) => (
                    <Zoom
                      triggerOnce
                      key={index}
                      className="gallery-item   all col-lg-4 col-md-6 col-sm-12"
                    >
                      <div className="gallery-block">
                        <div className="inner-box">
                          <div className="image">
                            <img src={img.photoFile} alt="" />
                          </div>
                          <a
                            href={img.photoFile}
                            className="overlink lightbox-image"
                            data-fancybox="gallery"
                          >
                            <span className="icon flaticon-cross"></span>
                          </a>
                        </div>
                      </div>
                    </Zoom>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Gallery;
