import React, { useEffect, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";
import { fetchAbout } from "../../data/about/aboutSlice";
// Import Fancybox CSS
const AboutUs = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();

  const language = useSelector((state) => state.language.language);
  const { about, loading, error } = useSelector((state) => state.about);
  useEffect(() => {
    dispatch(fetchAbout(language));
  }, [dispatch, language]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#d8b560", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          {" "}
          <Helmet>
            <title>{about?.metatagTitle}</title>
            <link
              rel="canonical"
              href={`https://www.dharahotels.com/${language}/aswan/benben-hotel/about-us`}
            />
            <meta name="description" content={about?.metatagDescription} />
            <meta property="og:title" content={about?.metatagTitleFacebook} />
            <meta
              property="og:description"
              content={about?.metatagDescriptionFacebook}
            />
            <meta name="twitter:title" content={about?.metatagTitleTwitter} />
            <meta
              name="twitter:description"
              content={about?.metatagDescriptionTwitter}
            />

            <meta name="robots" content="index, follow" />

            <style>
              {` .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }`}
            </style>

            <style>
              {`   
  .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }
 

 
   .starting-from {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
        border: 0px solid #000 !important;
        display: -webkit-flex !important;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        font-size: 13px !important;
        -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
        margin: 0 auto !important;
        max-width: 1000px !important;
        padding: 1px !important;
    }

    .starting-from--txt {
        color: #21acb7;
    }

    .starting-from .starting-from--price {
        font-size: 22px !important;
        font-weight: 600;
        color: #d8b560 !important;
    }`}
            </style>
          </Helmet>
          <section class="page-banner">
            <div
              class="image-layer"
              style={{
                backgroundImage: `url(${
                  isMobile
                    ? about?.pageBannerMobile
                    : isTablet
                    ? about?.pageBannerTablet
                    : about?.pageBanner
                })`,
              }}
            ></div>
            <div class="banner-bottom-pattern"></div>

            <div class="banner-inner">
              <div class="auto-container">
                <div class="inner-container clearfix centered">
                  <h1>{about?.pageTitle}</h1>
                  <div class="page-nav">
                    <ul class="bread-crumb clearfix">
                      <li>
                        <Link to="/">{t("Home")}</Link>
                      </li>
                      <li class="active">{about?.pageTitle}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            class="featured-section-four about-page"
            style={{ padding: "140px 0px 50px" }}
          >
            <span></span>
            <div class="circles-two">
              <div></div>
              <div></div>
            </div>
            <div class="auto-container">
              <div class="row clearfix">
                <div class="text-column col-lg-6 col-md-12 col-sm-12">
                  <div class="inner">
                    <div class="sec-title">
                      <h2>{about?.block1Title}</h2>
                    </div>
                    <div
                      class="text"
                      style={{ color: "#000", lineHeight: "2em" }}
                      dangerouslySetInnerHTML={{
                        __html: about?.block1Description,
                      }}
                    />
                  </div>
                </div>

                <div class="image-column col-lg-6 col-md-12 col-sm-12">
                  <div class="inner">
                    <span></span>
                    <div class="image-box clearfix">
                      <figure
                        class="image wow fadeInUp"
                        data-wow-delay="0ms"
                        data-wow-duration="1500ms"
                      >
                        <img src={about.block1Photo1} alt="" title="" />
                      </figure>
                      <figure
                        class="image wow fadeInRight"
                        data-wow-delay="0ms"
                        data-wow-duration="1500ms"
                      >
                        <img src={about.block1Photo2} alt="" title="" />
                      </figure>
                      <figure
                        class="image wow fadeInLeft"
                        data-wow-delay="300ms"
                        data-wow-duration="1500ms"
                      >
                        <img src={about.block1Photo3} alt="" title="" />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="featured-section">
            <div class="auto-container">
              <div class="featured-block">
                <div class="row clearfix">
                  <div class="text-column col-lg-6 col-md-12 col-sm-12">
                    <div
                      class="inner wow fadeInRight"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: about.block2Title,
                        }}
                      />
                      <div
                        class="text"
                        style={{
                          color: "#000",
                          lineHeight: "2em",
                          textAlign: "justify",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: about.block2Description,
                        }}
                      />
                    </div>
                  </div>

                  <div class="image-column col-lg-6 col-md-12 col-sm-12">
                    <div
                      class="inner wow fadeInLeft"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="image-box">
                        <figure class="image">
                          <img src={about.block2Photo} alt="" title="" />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="featured-block alternate">
                <div class="row clearfix">
                  <div class="text-column col-lg-6 col-md-12 col-sm-12">
                    <div class="inner">
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: about.block3Title,
                        }}
                      />
                      <div
                        class="text"
                        style={{
                          color: "#000",
                          lineHeight: "2em",
                          textAlign: "justify",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: about.block3Description,
                        }}
                      />
                    </div>
                  </div>

                  <div class="image-column col-lg-6 col-md-12 col-sm-12">
                    <div
                      class="inner wow fadeInRight"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="image-box">
                        <figure class="image">
                          <img src={about.block3Photo} alt="" title="" />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="video-section">
            <div
              class="image-layer"
              style={{ backgroundImage: `url(${about?.block4Photo})` }}
            ></div>
            <div class="auto-container">
              <div
                class="content-box wow zoomIn"
                data-wow-delay="0ms"
                data-wow-duration="2000ms"
              >
                <h1 dangerouslySetInnerHTML={{ __html: about?.block4Title }} />
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default AboutUs;
