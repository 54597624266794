import React, { useEffect, useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import BookingWidget from "../../components/BookingWidget";
import { fetchAccommodationDetails } from "../../data/accommodation/accommodationDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";
import parse from "html-react-parser";
SwiperCore.use([Navigation, Pagination, Autoplay]);
const AccommodationDetails = () => {
  const { filterBy, roomUrl } = useParams();

  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");

  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const { accommodationDetails, loading, error } = useSelector(
    (state) => state.accommodationDetails
  );

  useEffect(() => {
    dispatch(fetchAccommodationDetails({ language, roomUrl }));
  }, [dispatch, language, roomUrl]);
  const carouselRef = useRef(null);
  const prevSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };
  const nextSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#d8b560", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{accommodationDetails?.metatagTitle}</title>
            <link
              rel="canonical"
              href={`https://www.dharahotels.com/en/aswan/benben-hotel/accommodation/${filterBy}/${roomUrl}`}
            />
            <meta name="robots" content="index, follow" />
            <meta
              name="description"
              content={accommodationDetails?.metatagDescription}
            />
            <meta
              property="og:title"
              content={accommodationDetails?.metatagTitleFacebook}
            />
            <meta
              property="og:description"
              content={accommodationDetails?.metatagDescriptionFacebook}
            />
            <meta
              name="twitter:title"
              content={accommodationDetails?.metatagTitleTwitter}
            />
            <meta
              name="twitter:description"
              content={accommodationDetails?.metatagDescriptionTwitter}
            />
            <style>
              {` .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }`}
            </style>
            <style>
              {`   
  .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }
 

 
   .starting-from {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
        border: 0px solid #000 !important;
        display: -webkit-flex !important;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        font-size: 13px !important;
        -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
        margin: 0 auto !important;
        max-width: 1000px !important;
        padding: 1px !important;
    }

    .starting-from--txt {
        color: #21acb7;
    }

    .starting-from .starting-from--price {
        font-size: 22px !important;
        font-weight: 600;
        color: #d8b560 !important;
    }`}
            </style>{" "}
            <link
              rel="stylesheet"
              property="stylesheet"
              href="widgets/app.css"
            />
            <script type="text/javascript" src="widgets/app.js" />
          </Helmet>
          <section class="page-banner ext-banner">
            <div
              class="image-layer"
              style={{
                backgroundImage: `url(${
                  isMobile
                    ? accommodationDetails?.roomBannerMobile
                    : isTablet
                    ? accommodationDetails?.roomBannerTablet
                    : accommodationDetails?.roomBanner
                })`,
              }}
            ></div>
            <div class="banner-bottom-pattern"></div>

            <div class="banner-inner">
              <div class="auto-container">
                <div class="inner-container clearfix"></div>
              </div>
            </div>
          </section>
          <section className="room-single" style={{ paddingBottom: "80px" }}>
            <div className="circles-two">
              <div className="c-1"></div>
              <div className="c-2"></div>
            </div>
            <span className="dotted-pattern dotted-pattern-3"></span>
            <span className="tri-pattern tri-pattern-3"></span>
            <div className="auto-container">
              <div
                className="upper-box wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="upper-inner">
                  <h2>{accommodationDetails?.roomName}</h2>
                  <div className="pricing clearfix">
                    <div className="price">
                      {accommodationDetails?.roomPMSCode && (
                        <>
                          <div
                            id={`fb-widget-1`}
                            className="fb-widget"
                            data-fbConfig="1"
                          ></div>
                          <script
                            className="fb-widget-config"
                            data-fbConfig="1"
                            type="application/json"
                          >
                            {JSON.stringify({
                              params: [
                                {
                                  startingFrom: {
                                    theme: "sf--thm-1",
                                    call2action: "none",
                                    roomRateFiltering: 2,
                                    rateFilter: [],
                                    roomFilter: [
                                      accommodationDetails?.roomPMSCode,
                                    ],
                                    dayRange: 180,
                                  },
                                  currency: "EGP",
                                  locale: accommodationDetails?.bookingLang,
                                  pricesDisplayMode: "normal",
                                  offerComputeRule: "lowerPrice",
                                  maxAdults: 4,
                                  maxChildren: 0,
                                  mainColor: "#b0a06c",
                                  themeDark: false,
                                  openFrontInNewTab: true,
                                  property: accommodationDetails?.hotelPMSCode,
                                  title: accommodationDetails?.hotelName,
                                  childrenMaxAge: 12,
                                  groupsNames: "",
                                  quicksearch: {
                                    showChildrenAges: false,
                                  },
                                  fbWidget: "StartingFrom",
                                },
                              ],
                              commonParams: {
                                redirectUrl:
                                  "https://redirect.fastbooking.com/DIRECTORY/dispoprice.phtml",
                                showPropertiesList: false,
                                demoMode: false,
                                allowGroupSelection: false,
                                groupName: "",
                              },
                              _authCode:
                                "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzY29wZXMiOiIuKiIsInByb3BlcnRpZXMiOiJlZ2FzdzAwMDAxIiwiZ3JvdXBzIjoiXiQiLCJmb3IiOiJCYWNrb2ZmaWNlIiwiaWF0IjoxNjQzODQyMDU1LCJqaWQiOiI2MWZhZWRlNzU5ZGJhIn0.EQ6Iyw6Vu82AaNXUfDsA3LqeoTY1Et930P3hVhoipdg",
                              propertyIndex: 0,
                              version: "1.46.0",
                              baseHost: "websdk.fastbooking-services.com",
                            })}
                          </script>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className="room-single"
                    style={{ paddingBottom: "20px" }}
                  >
                    <div
                      className="details-box"
                      style={{
                        paddingTop: "1px",
                        marginTop: "1px",
                      }}
                    >
                      <ul className="info clearfix">
                        {accommodationDetails?.roomView && (
                          <li style={{ marginBottom: "10px" }}>
                            <span style={{ color: "#21acb7" }}>
                              <i
                                className="fa fa-binoculars"
                                aria-hidden="true"
                              ></i>
                              <span style={{ paddingLeft: "5px" }}>
                                {t("View")}:{" "}
                              </span>
                            </span>
                            {accommodationDetails?.roomView}
                          </li>
                        )}
                        {accommodationDetails?.roomSize && (
                          <li style={{ marginBottom: "10px" }}>
                            <span style={{ color: "#21acb7" }}>
                              <i
                                className="fa fa-arrows-alt"
                                aria-hidden="true"
                              ></i>
                              <span style={{ paddingLeft: "5px" }}>
                                {t("Size")}:{" "}
                              </span>
                            </span>
                            {accommodationDetails?.roomSize}
                          </li>
                        )}
                        {accommodationDetails?.maxOccupancy && (
                          <li style={{ marginBottom: "10px" }}>
                            <span style={{ color: "#21acb7" }}>
                              <span
                                className="fa fa-user"
                                style={{ paddingRight: "1px" }}
                              ></span>
                              <span style={{ paddingLeft: "5px" }}>
                                {t("Max Occupancy")}:{" "}
                              </span>
                            </span>
                            {accommodationDetails?.maxOccupancy}
                          </li>
                        )}
                        {accommodationDetails?.roomBed && (
                          <li style={{ marginBottom: "10px" }}>
                            <span style={{ color: "#21acb7" }}>
                              <i className="fa fa-bed" aria-hidden="true"></i>
                              <span style={{ paddingLeft: "5px" }}>
                                {t("Bed")}:{" "}
                              </span>
                            </span>
                            {accommodationDetails?.roomBed}
                          </li>
                        )}
                        {/* <li style={{ marginBottom: "10px" }}>
                          <span style={{ color: "#21acb7" }}>
                            <img
                              src="images/uploads/icons/jacuzzi.png"
                              style={{ paddingRight: "1px" }}
                              width="15"
                              className="img-responsive"
                              alt=""
                            />
                            <span style={{ paddingLeft: "5px" }}>
                              {t("Jacuzzi")}:{" "}
                            </span>
                          </span>
                          Size here
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: accommodationDetails?.roomDetails,
                    }}
                  />
                </div>
              </div>

              <div className="details-box">
                <div className="details-inner">
                  <h3>{t("Room Amenities")}</h3>
                  <div
                    className="row"
                    style={{
                      color: "#000",
                      fontSize: "15px",
                      lineHeight: "24px",
                    }}
                  >
                    {accommodationDetails.roomAmenities?.map((item, index) => (
                      <div
                        className="col-lg-4 col-md-6 col-xs-12"
                        style={{ paddingBottom: "20px" }}
                        key={index}
                      >
                        <img
                          src={item.roomAmenitiesPhoto}
                          style={{ paddingRight: "10px" }}
                          width="45"
                          className="img-responsive"
                          alt={item.roomAmenitiesName}
                        />
                        {item.roomAmenitiesName}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Gallery Section  */}
          {accommodationDetails.roomsGalleries?.length > 0 && (
            <section
              className="gallery-section"
              style={{ background: "#edf0f5 !important" }}
            >
              <span></span>
              <span className="tri-pattern tri-pattern-3"></span>
              <div className="auto-container">
                <div className="sec-title">
                  <h2>{t("Gallery")}</h2>
                </div>
              </div>{" "}
              <div className="carousel-box">
                <div
                  className="owl-nav"
                  style={{ position: "relative", right: "10vw", top: "-13vh" }}
                >
                  <button
                    type="button"
                    role="presentation"
                    className="owl-prev"
                    onClick={prevSlide}
                    style={{
                      right: "4vw",
                      position: "absolute",
                      fontSize: "22px",
                      fontWeight: "700",
                      background: "transparent",
                    }}
                  >
                    <span
                      className="icon flaticon-left-arrow-1"
                      style={{ color: "rgba(0,0,0,0.30)" }}
                    ></span>
                  </button>
                  <button
                    type="button"
                    role="presentation"
                    className="owl-next"
                    onClick={nextSlide}
                    style={{
                      right: "2vw",
                      position: "absolute",
                      fontSize: "20px",
                      fontWeight: "700",
                      background: "transparent",
                    }}
                  >
                    <span
                      className="icon flaticon-arrows-10"
                      style={{ color: "rgba(0,0,0,0.30)" }}
                    ></span>
                  </button>
                </div>
                <OwlCarousel
                  className="gallery-carousel owl-theme"
                  loop
                  margin={10}
                  nav={false}
                  items={3}
                  ref={carouselRef}
                  autoplay
                  autoplayTimeout={3000}
                  smartSpeed={1000}
                  animateIn="animate__fadeIn" // Add animation classes for slide in
                  animateOut="animate__fadeOut" // Add animation classes for slide out
                  responsive={{
                    0: {
                      items: 1, // 1 item for screens up to 600px
                    },
                    600: {
                      items: 1, // 1 item for screens from 600px to 1000px
                    },
                    1000: {
                      items: 3, // 2 items for screens over 1000px
                    },
                  }}
                >
                  {accommodationDetails.roomsGalleries?.map((image, index) => (
                    <div className="slide-item" key={index}>
                      <div className="gallery-block">
                        <div className="inner-box">
                          <div className="image">
                            <img
                              src={image.photoFile}
                              alt={`Gallery Image ${index}`}
                            />
                          </div>
                          <a
                            href={image.photoFile}
                            className="overlink lightbox-image"
                            data-fancybox="gallery-1"
                          >
                            <span className="icon flaticon-cross"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </section>
          )}
          <section
            class="filters-section alternate"
            style={{
              paddingBottom: "50px",
              paddingTop: "100px",
              background: "#edf0f5",
            }}
          >
            <div class="sec-title centered">
              <h2>{t("Check Availability")}</h2>
            </div>
            <BookingWidget backgrounds="none" />
          </section>
          <section
            class="rooms-section-two"
            style={{ padding: "100px 0px 80px " }}
          >
            <div class="outer-container">
              <div class="auto-container">
                <div class="sec-title centered">
                  <h2>{t("Other Accommodation")}</h2>
                </div>

                <div className="carousel-box">
                  <OwlCarousel
                    className="rooms-carousel owl-theme"
                    items={2}
                    loop
                    margin={10}
                    nav
                    responsive={{
                      0: {
                        items: 1, // 1 item for screens up to 600px
                      },
                      600: {
                        items: 1, // 1 item for screens from 600px to 1000px
                      },
                      1000: {
                        items: 2, // 2 items for screens over 1000px
                      },
                    }}
                  >
                    {accommodationDetails.otherRooms?.map((room, index) => (
                      <div key={room.roomUrl} className="room-block-two">
                        <div className="inner-box">
                          <div className="image-box">
                            <figure className="image">
                              <Link
                                to={`/accommodation/${room.roomTypeCategoryName}/${room.roomUrl}`}
                              >
                                <img
                                  src={room.roomPhoto}
                                  alt={room.roomName}
                                  title={room.roomName}
                                />
                              </Link>
                            </figure>
                          </div>
                          <div className="lower-box">
                            <h4>
                              <Link
                                to={`/accommodation/${room.roomTypeCategoryName}/${room.roomUrl}`}
                              >
                                {room.roomName}
                              </Link>
                            </h4>
                            <div
                              className="text"
                              style={{ margin: "0 0 10px", color: "#000" }}
                            >
                              <p className="line-clamp2">{room.roomSummery}</p>
                            </div>
                            <ul
                              className="info clearfix"
                              style={{ paddingLeft: "10px" }}
                            >
                              {room.roomView && (
                                <li>
                                  <span style={{ color: "#21acb7" }}>
                                    <i
                                      className="fa fa-binoculars"
                                      aria-hidden="true"
                                    ></i>
                                    <span style={{ paddingLeft: "5px" }}>
                                      {t("View")}:
                                    </span>
                                  </span>{" "}
                                  {room.roomView}
                                </li>
                              )}
                              {room.roomBed && (
                                <li>
                                  <span style={{ color: "#21acb7" }}>
                                    <i
                                      className="fa fa-bed"
                                      aria-hidden="true"
                                    ></i>
                                    <span style={{ paddingLeft: "5px" }}>
                                      {t("Bed")}:
                                    </span>
                                  </span>{" "}
                                  {room.roomBed}
                                </li>
                              )}
                              {room.roomSize && (
                                <li>
                                  <span style={{ color: "#21acb7" }}>
                                    <i
                                      className="fa fa-arrows-alt"
                                      aria-hidden="true"
                                    ></i>
                                    <span style={{ paddingLeft: "5px" }}>
                                      {t("Size")}:
                                    </span>
                                  </span>{" "}
                                  {room.roomSize}
                                </li>
                              )}
                              {room.maxOccupancy && (
                                <li>
                                  <span style={{ color: "#21acb7" }}>
                                    <span className="icon flaticon-user-2"></span>
                                    <span style={{ paddingLeft: "5px" }}>
                                      {t("Max Occupancy")}:{" "}
                                    </span>
                                  </span>{" "}
                                  {room.maxOccupancy}
                                </li>
                              )}
                            </ul>
                            {room.roomPMSCode && (
                              <>
                                <div
                                  id={`fb-widget-${index}`}
                                  className="fb-widget"
                                  data-fbConfig={index}
                                  style={{ marginTop: "20px" }}
                                ></div>
                                <script
                                  className="fb-widget-config"
                                  data-fbConfig={index}
                                  type="application/json"
                                >
                                  {JSON.stringify({
                                    params: [
                                      {
                                        startingFrom: {
                                          theme: "sf--thm-1",
                                          call2action: "none",
                                          roomRateFiltering: 2,
                                          rateFilter: [],
                                          roomFilter: [room.roomPMSCode],
                                          dayRange: 180,
                                        },
                                        currency: "EGP",
                                        locale: room.bookingLang,
                                        pricesDisplayMode: "normal",
                                        offerComputeRule: "lowerPrice",
                                        maxAdults: 4,
                                        maxChildren: 0,
                                        mainColor: "#b0a06c",
                                        themeDark: false,
                                        openFrontInNewTab: true,
                                        property:
                                          accommodationDetails?.hotelPMSCode,
                                        title: accommodationDetails?.hotelName,
                                        // property: "egasw00001",
                                        // title: "BenBen by Dhara Hotels",
                                        childrenMaxAge: 12,
                                        groupsNames: "",
                                        quicksearch: {
                                          showChildrenAges: false,
                                        },
                                        fbWidget: "StartingFrom",
                                      },
                                    ],
                                    commonParams: {
                                      redirectUrl:
                                        "https://redirect.fastbooking.com/DIRECTORY/dispoprice.phtml",
                                      showPropertiesList: false,
                                      demoMode: false,
                                      allowGroupSelection: false,
                                      groupName: "",
                                    },
                                    _authCode:
                                      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzY29wZXMiOiIuKiIsInByb3BlcnRpZXMiOiJlZ2FzdzAwMDAxIiwiZ3JvdXBzIjoiXiQiLCJmb3IiOiJCYWNrb2ZmaWNlIiwiaWF0IjoxNjQzODQyMDU1LCJqaWQiOiI2MWZhZWRlNzU5ZGJhIn0.EQ6Iyw6Vu82AaNXUfDsA3LqeoTY1Et930P3hVhoipdg",
                                    propertyIndex: 0,
                                    version: "1.46.0",
                                    baseHost: "websdk.fastbooking-services.com",
                                  })}
                                </script>
                              </>
                            )}
                            <div className="link-box">
                              <Link
                                to={`/accommodation/${room.roomTypeCategoryName}/${room.roomUrl}`}
                                className="theme-btn btn-style-three"
                              >
                                <span className="btn-title">
                                  {t("Learn More")}
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default AccommodationDetails;
