import React, { useEffect, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchAccommodation } from "../../data/accommodation/accommodationSlice";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";

const Accommodation = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(t("All"));
  const language = useSelector((state) => state.language.language);
  const { accommodation, loading, error } = useSelector(
    (state) => state.accommodation
  );

  useEffect(() => {
    dispatch(fetchAccommodation(language));
  }, [dispatch, language]);

  // Filter data based on the selected filter
  const filteredData =
    filter === t("All")
      ? accommodation.roomsList
      : accommodation.roomsList?.filter(
          (item) => item.roomTypeCategoryName === filter
        );
  useEffect(() => {
    // Add the FastBooking script to the page
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "/widgets/app.js";
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#d8b560", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          {" "}
          <Helmet>
            <title>{accommodation.pageDetails?.pageMetatagTitle}</title>
            <link
              rel="canonical"
              href={`https://www.dharahotels.com/en/aswan/benben-hotel/accommodation`}
            />
            <meta
              name="description"
              content={accommodation.pageDetails?.pageMetatagDescription}
            />
            <meta
              property="og:title"
              content={accommodation.pageDetails?.metatagTitleFacebook}
            />
            <meta
              property="og:description"
              content={accommodation.pageDetails?.metatagDescriptionFacebook}
            />
            <meta
              name="twitter:title"
              content={accommodation.pageDetails?.metatagTitleTwitter}
            />
            <meta
              name="twitter:description"
              content={accommodation.pageDetails?.metatagDescriptionTwitter}
            />

            <link
              rel="stylesheet"
              property="stylesheet"
              href="widgets/app.css"
            />
            <script type="text/javascript" src="widgets/app.js"></script>
            <style>
              {` .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }`}
            </style>

            <style>
              {`   
  .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }
 

 
   .starting-from {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
        border: 0px solid #000 !important;
        display: -webkit-flex !important;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        font-size: 13px !important;
        -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
        margin: 0 auto !important;
        max-width: 1000px !important;
        padding: 1px !important;
    }

    .starting-from--txt {
        color: #21acb7;
    }

    .starting-from .starting-from--price {
        font-size: 22px !important;
        font-weight: 600;
        color: #d8b560 !important;
    }`}
            </style>
          </Helmet>
          <section class="page-banner">
            <div
              class="image-layer"
              style={{
                backgroundImage: `url(${
                  isMobile
                    ? accommodation.pageDetails?.pageBannerMobile
                    : isTablet
                    ? accommodation.pageDetails?.pageBannerTablet
                    : accommodation.pageDetails?.pageBannerPC
                })`,
              }}
            ></div>
            <div class="banner-bottom-pattern"></div>

            <div class="banner-inner">
              <div class="auto-container">
                <div class="inner-container clearfix centered">
                  <h1>{accommodation.pageDetails?.pageTitle}</h1>
                  <div class="page-nav">
                    <ul class="bread-crumb clearfix">
                      <li>
                        <Link to="/">{t("Home")}</Link>
                      </li>
                      <li class="active">
                        {accommodation.pageDetails?.pageTitle}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="packages-section">
            <div className="auto-container">
              <div
                className="sec-title centered"
                style={{ maxWidth: "100%" }}
                dangerouslySetInnerHTML={{
                  __html: accommodation.pageDetails?.pageText,
                }}
              />

              <div className="mixit-gallery filter-gallery">
                <div className="filters clearfix">
                  <ul className="filter-tabs filter-btns clearfix">
                    <li
                      className={`filter ${
                        filter === t("All") ? "active" : ""
                      }`}
                      onClick={() => setFilter(t("All"))}
                    >
                      {t("All")}
                    </li>
                    <li
                      className={`filter ${
                        filter === t("Rooms") ? "active" : ""
                      }`}
                      onClick={() => setFilter(t("Rooms"))}
                    >
                      {t("Rooms")}
                    </li>
                    <li
                      className={`filter ${
                        filter === t("Suites") ? "active" : ""
                      }`}
                      onClick={() => setFilter(t("Suites"))}
                    >
                      {t("Suites")}
                    </li>
                  </ul>
                </div>

                <div className="filter-list row clearfix">
                  {filteredData?.map((item, index) => (
                    <Zoom
                      triggerOnce
                      key={item.roomUrl}
                      className={`offer-block-two  ${item.roomTypeCategoryName} col-lg-6 col-md-12 col-sm-12`}
                    >
                      <div className="inner-box  ">
                        <div className="image-box">
                          <figure className="image">
                            <Link
                              to={`/accommodation/${item.filterBy}/${item.roomUrl}`}
                            >
                              <img
                                src={item.roomPhoto}
                                alt={item.roomName}
                                title={item.roomName}
                              />
                            </Link>
                          </figure>
                        </div>
                        <div className="lower-box">
                          <h4>
                            <Link
                              to={`/accommodation/${item.filterBy}/${item.roomUrl}`}
                              style={{
                                color: "#21acb7",
                                fontFamily: "Raleway, sans-serif",
                                fontSize: "30px",
                              }}
                            >
                              {item.roomName}
                            </Link>
                          </h4>
                          <div className="text">
                            <p className="line-clamp2">{item.roomSummery}</p>
                            <ul
                              className="info clearfix"
                              style={{
                                paddingLeft: "10px",
                                paddingTop: "10px",
                              }}
                            >
                              {item.roomView && (
                                <li>
                                  <span style={{ color: "#21acb7" }}>
                                    <i
                                      className="fa fa-binoculars"
                                      aria-hidden="true"
                                    ></i>
                                    <span style={{ paddingLeft: "5px" }}>
                                      {t("View")}:
                                    </span>
                                  </span>{" "}
                                  {item.roomView}
                                </li>
                              )}
                              {item.roomBed && (
                                <li>
                                  <span style={{ color: "#21acb7" }}>
                                    <i
                                      className="fa fa-bed"
                                      aria-hidden="true"
                                    ></i>
                                    <span style={{ paddingLeft: "5px" }}>
                                      {t("Bed")}:
                                    </span>
                                  </span>{" "}
                                  {item.roomBed}
                                </li>
                              )}
                              {item.roomSize && (
                                <li>
                                  <span style={{ color: "#21acb7" }}>
                                    <i
                                      className="fa fa-arrows-alt"
                                      aria-hidden="true"
                                    ></i>
                                    <span style={{ paddingLeft: "5px" }}>
                                      {t("Size")}:
                                    </span>
                                  </span>{" "}
                                  {item.roomSize}
                                </li>
                              )}
                              {item.maxOccupancy && (
                                <li>
                                  <span style={{ color: "#21acb7" }}>
                                    <span className="fa fa-user"></span>
                                    <span style={{ paddingLeft: "5px" }}>
                                      {t("Max Occupancy")}:
                                    </span>
                                  </span>{" "}
                                  {item.maxOccupancy}
                                </li>
                              )}
                            </ul>
                          </div>
                          {item.roomPmscode && (
                            <>
                              <div
                                id={`fb-widget-${index}`}
                                className="fb-widget"
                                data-fbConfig={index}
                              ></div>
                              <script
                                className="fb-widget-config"
                                data-fbConfig={index}
                                type="application/json"
                              >
                                {JSON.stringify({
                                  params: [
                                    {
                                      startingFrom: {
                                        theme: "sf--thm-1",
                                        call2action: "none",
                                        roomRateFiltering: 2,
                                        rateFilter: [],
                                        roomFilter: [item.roomPmscode],
                                        dayRange: 180,
                                      },
                                      currency: "EGP",
                                      locale: item.bookingLang,
                                      pricesDisplayMode: "normal",
                                      offerComputeRule: "lowerPrice",
                                      maxAdults: 4,
                                      maxChildren: 0,
                                      mainColor: "#b0a06c",
                                      themeDark: false,
                                      openFrontInNewTab: true,
                                      property:
                                        accommodation.pageDetails?.hotelPMSCode,
                                      title:
                                        accommodation.pageDetails?.hotelName,
                                      // property: "egasw00001",
                                      // title: "BenBen by Dhara Hotels",
                                      childrenMaxAge: 12,
                                      groupsNames: "",
                                      quicksearch: { showChildrenAges: false },
                                      fbWidget: "StartingFrom",
                                    },
                                  ],
                                  commonParams: {
                                    redirectUrl:
                                      "https://redirect.fastbooking.com/DIRECTORY/dispoprice.phtml",
                                    showPropertiesList: false,
                                    demoMode: false,
                                    allowGroupSelection: false,
                                    groupName: "",
                                  },
                                  _authCode:
                                    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzY29wZXMiOiIuKiIsInByb3BlcnRpZXMiOiJlZ2FzdzAwMDAxIiwiZ3JvdXBzIjoiXiQiLCJmb3IiOiJCYWNrb2ZmaWNlIiwiaWF0IjoxNjQzODQyMDU1LCJqaWQiOiI2MWZhZWRlNzU5ZGJhIn0.EQ6Iyw6Vu82AaNXUfDsA3LqeoTY1Et930P3hVhoipdg",
                                  propertyIndex: 0,
                                  version: "1.46.0",
                                  baseHost: "websdk.fastbooking-services.com",
                                })}
                              </script>
                            </>
                          )}

                          <div className="link-box">
                            <Link
                              to={`/accommodation/${item.filterBy}/${item.roomUrl}`}
                              className="theme-btn btn-style-three"
                            >
                              <span className="btn-title">
                                {t("More Details")}
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Zoom>
                  ))}
                </div>
              </div>
            </div>
          </section>{" "}
        </>
      )}
    </>
  );
};

export default Accommodation;
