import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import your translation files
import en from "./locales/en.json";
import es from "./locales/es.json";
import fr from "./locales/fr.json";
import cn from "./locales/cn.json";
const resources = {
  en: { translation: en },
  es: { translation: es },
  fr: { translation: fr },
  cn: { translation: cn },
};

i18n
  .use(LanguageDetector) // Detect language from browser or other sources
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // default language
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
  });

export default i18n;
