import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "./homeSlice";
import hotelsReducer from "./hotelsSlice";
import termsReducer from "./termsSlice";
import privacyReducer from "./privacySlice";
import layoutGroupReducer from "./layoutGroupSlice";
import homeGroupReducer from "./homeGroup/homeGroupSlice";
import meetingsGroupReducer from "./meetingsGroup/meetingsGroupSlice";
import onlineCheckInReducer from "./online-check-in/onlineCheckInSlice";
import faqGroupReducer from "./faqGroup/faqGroupSlice";
import contactGroupReducer from "./contactGroup/contactGroupSlice";
import layoutHotelReducer from "./layoutHotelSlice";
import homeHotelReducer from "./homeHotel/homeHotelSlice";
import accommodationReducer from "./accommodation/accommodationSlice";
import accommodationTypeReducer from "./accommodation/accommodationTypeSlice";
import accommodationDetailsReducer from "./accommodation/accommodationDetailsSlice";
import diningReducer from "./dining/diningSlice";
import diningDetailsReducer from "./dining/diningDetailsSlice";
import facilitiesReducer from "./facilities/facilitiesSlice";
import offersReducer from "./offers/offersSlice";
import offersDetailsReducer from "./offers/offersDetailsSlice";
import facilityDetailsReducer from "./facilities/facilityDetailsSlice";
import meetingsReducer from "./meetings/meetingsSlice";
import meetingDetailsReducer from "./meetings/meetingDetailsSlice";
import galleryReducer from "./gallery/gallerySlice";
import contactHotelReducer from "./contactHotel/contactHotelSlice";
import aboutReducer from "./about/aboutSlice";
import aboutDharaReducer from "./about/aboutDharaSlice";
import storyReducer from "./about/storySlice";
import diningTypeReducer from "./diningtype/diningTypeSlice";
import faqHotelsReducer from "./faqHotels/faqHotelsSlice";
import languageReducer from "./languageSlice";
import propertiesReducer from "./bookingEngingSlice";
export const store = configureStore({
  reducer: {
    language: languageReducer,
    layoutGroup: layoutGroupReducer,
    properties: propertiesReducer,
    homeGroup: homeGroupReducer,
    meetingsGroup: meetingsGroupReducer,
    onlineCheckIn: onlineCheckInReducer,
    faqGroup: faqGroupReducer,
    contactGroup: contactGroupReducer,
    layoutHotel: layoutHotelReducer,
    homeHotel: homeHotelReducer,
    home: homeReducer,
    hotels: hotelsReducer,
    terms: termsReducer,
    privacy: privacyReducer,
    accommodation: accommodationReducer,
    accommodationType: accommodationTypeReducer,
    accommodationDetails: accommodationDetailsReducer,
    dining: diningReducer,
    diningDetails: diningDetailsReducer,
    facilities: facilitiesReducer,
    facilityDetails: facilityDetailsReducer,
    offers: offersReducer,
    offersDetails: offersDetailsReducer,
    meetings: meetingsReducer,
    meetingDetails: meetingDetailsReducer,
    gallery: galleryReducer,
    contactHotel: contactHotelReducer,
    about: aboutReducer,
    aboutDhara: aboutDharaReducer,
    story: storyReducer,
    diningType: diningTypeReducer,
    faqHotels: faqHotelsReducer,
  },
});
