import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ScrollToTop from "./ScrollToTop.jsx";
// import "react-image-lightbox/style.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import Layout from "./components/Layout.jsx";
import Home from "./pages/Home.jsx";
import PageTracker from "./components/PageTracker";
import { setLanguage } from "./data/languageSlice.js";
import NotFound from "./pages/NotFound";
import Accommodation from "./pages/accommodation/Accommodation.jsx";
import InternalLayout from "./components/InternalLayout.jsx";
import AccommodationDetails from "./pages/accommodation/AccommodationDetails.jsx";
import AccommodationType from "./pages/accommodation/AccommodationType.jsx";
import Dining from "./pages/dining/Dining.jsx";
import Gallery from "./pages/gallery/Gallery.jsx";
import ContactUs from "./pages/contact-us/ContactUs.jsx";
import Activities from "./pages/activities/Activities.jsx";
import ActivitiesDetails from "./pages/activities/ActivitiesDetails.jsx";
import Offers from "./pages/offers/Offers.jsx";
import OfferDetails from "./pages/offers/OfferDetails";
import AboutUs from "./pages/about-us/AboutUS.jsx";
import DharaHotels from "./pages/dhara-hotels/DharaHotels.jsx";
import Story from "./pages/story/Story.jsx";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy.jsx";

import TermsAndConditions from "./pages/terms-and-conditions/TermsAndConditions";
import Covid19 from "./pages/covid-19/Covid19.jsx";
import { fetchProperties } from "./data/bookingEngingSlice.js";
import CookieConsent from "./components/CookieConsent.jsx";
// import TemporaryLinks from "./components/TemporaryLinks";
function App() {
  const language = useSelector((state) => state.language.language);
  const [basename, setBasename] = useState("/en/aswan/benben-hotel");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProperties(language));
  }, [language]);

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const pathSegments = currentUrl.pathname.split("/").filter(Boolean); // Split and remove empty segments

    if (
      pathSegments.length < 3 ||
      pathSegments[1] !== "aswan" ||
      pathSegments[2] !== "benben-hotel"
    ) {
      const newPath = `/en/aswan/benben-hotel${currentUrl.pathname}`;
      window.location.href = `${currentUrl.origin}${newPath}${currentUrl.search}`;
    } else {
      if (["en", "cn", "es", "fr"].includes(pathSegments[0])) {
        dispatch(setLanguage(pathSegments[0]));
        setBasename(`/${pathSegments[0]}/aswan/benben-hotel`);
      } else {
        window.location.href = `${currentUrl.origin}/${language}/aswan/benben-hotel${currentUrl.pathname}`;
      }
    }

    const head = document.head;
    const existingLink = document.getElementById("rtl-stylesheet");

    if (language === "ar") {
      if (!existingLink) {
        const link = document.createElement("link");
        link.id = "rtl-stylesheet"; // Assign an ID for easy access
        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = "bootstrap-rtl.min.css";
        head.appendChild(link);
      }
    } else {
      if (existingLink) {
        head.removeChild(existingLink);
      }
    }
  }, [language, dispatch]);
  return (
    <HelmetProvider>
      <Router basename={basename}>
        <CookieConsent />
        <PageTracker />
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route path="/" element={<InternalLayout />}>
            <Route path="dhara-hotels" element={<DharaHotels />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="story" element={<Story />} />
            <Route path="accommodation" element={<Accommodation />} />
            <Route
              path="accommodation/:FilterByURL"
              element={<AccommodationType />}
            />
            <Route
              path="accommodation/:filterBy/:roomUrl"
              element={<AccommodationDetails />}
            />
            <Route path="dining" element={<Dining />} />
            <Route path="activities" element={<Activities />} />
            <Route
              path="activities/:facilityUrl"
              element={<ActivitiesDetails />}
            />
            <Route path="offers" element={<Offers />} />
            <Route path="offers/:OfferUrl" element={<OfferDetails />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="covid-19" element={<Covid19 />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-conditions" element={<TermsAndConditions />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>{" "}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
