import React, { useEffect, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import { fetchAccommodationType } from "../../data/accommodation/accommodationTypeSlice";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader";

const AccommodationType = () => {
  const { FilterByURL } = useParams();
  const rooms = [
    {
      title: "Superior Double Room",
      imageSrc:
        "images/uploads/benben-hotel-aswan-20221164712954-superior-double-room.jpg",
      description:
        "Located on the first level of the BenBen hotel; featuring a side Nile view and vast scenery of the rocks of Heissa Island.",
      details: {
        view: "Nile view",
        bed: "Double Bed",
        size: "23 m²",
        max: "2 Adults",
      },
      roomUrl: "superior-double-room",
      fbConfig: 1,
    },
    {
      title: "Deluxe King Room",
      imageSrc:
        "images/uploads/benben-hotel-aswan-2022151532139-deluxe-king-room.png",
      description:
        "Offering the comforts of comfortable beds, a walk-in shower, and a great advantage of an in-terrace Jacuzzi with a magnificent Nile view.",
      details: {
        view: "Nile View",
        bed: "Extra-large double bed",
        size: "41 m²",
        max: "2 Adults",
      },
      roomUrl: "deluxe-king-room",
      fbConfig: 2,
    },
    {
      title: "Deluxe Queen Room",
      imageSrc:
        "images/uploads/benben-hotel-aswan-20231412450196-deluxe-queen-room.png",
      description:
        "Offering the comforts of comfortable beds, a walk-in shower, and a great advantage of an in-terrace Jacuzzi with a magnificent Nile view.",
      details: {
        view: "Nile View",
        bed: "Extra-large double bed",
        size: "35 m²",
        max: "2 Adults",
      },
      roomUrl: "deluxe-queen-room",
      fbConfig: 3,
    },
    {
      title: "Premium Double Room",
      imageSrc:
        "images/uploads/benben-hotel-aswan-202314122059946-premium-double-room.png",
      description:
        "This room is right in the middle of the Nile River with a spectacular view of the Philae temple from your outdoor private in terrace Jacuzzi",
      details: {
        view: "Philae Temple and Nile View",
        bed: "Double bed",
        size: "25 m²",
        max: "2 Adults",
      },
      roomUrl: "premium-double-room",
      fbConfig: 4,
    },
  ];
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(t("All"));
  const language = useSelector((state) => state.language.language);
  const { accommodationType, loading, error } = useSelector(
    (state) => state.accommodationType
  );
  useEffect(() => {
    dispatch(fetchAccommodationType({ language, FilterByURL }));
  }, [dispatch, language, FilterByURL]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#d8b560", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          {" "}
          <Helmet>
            <title>{accommodationType.pageDetails?.pageMetatagTitle}</title>
            <link
              rel="canonical"
              href={`https://www.dharahotels.com/en/aswan/benben-hotel/accommodation`}
            />
            <meta
              name="description"
              content={accommodationType.pageDetails?.pageMetatagDescription}
            />
            <meta
              property="og:title"
              content={accommodationType.pageDetails?.metatagTitleFacebook}
            />
            <meta
              property="og:description"
              content={
                accommodationType.pageDetails?.metatagDescriptionFacebook
              }
            />
            <meta
              name="twitter:title"
              content={accommodationType.pageDetails?.metatagTitleTwitter}
            />
            <meta
              name="twitter:description"
              content={accommodationType.pageDetails?.metatagDescriptionTwitter}
            />
            <style>
              {` .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }`}
            </style>
            <style>
              {`   
  .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }
 

 
   .starting-from {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
        border: 0px solid #000 !important;
        display: -webkit-flex !important;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        font-size: 13px !important;
        -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
        margin: 0 auto !important;
        max-width: 1000px !important;
        padding: 1px !important;
    }

    .starting-from--txt {
        color: #21acb7;
    }

    .starting-from .starting-from--price {
        font-size: 22px !important;
        font-weight: 600;
        color: #d8b560 !important;
    }`}
            </style>{" "}
            <link
              rel="stylesheet"
              property="stylesheet"
              href="widgets/app.css"
            />
            <script type="text/javascript" src="widgets/app.js"></script>
          </Helmet>
          <section class="page-banner">
            <div
              class="image-layer"
              style={{
                backgroundImage: `url(${
                  isMobile
                    ? accommodationType.pageDetails?.pageBannerMobile
                    : isTablet
                    ? accommodationType.pageDetails?.pageBannerTablet
                    : accommodationType.pageDetails?.pageBannerPC
                })`,
              }}
            ></div>
            <div class="banner-bottom-pattern"></div>

            <div class="banner-inner">
              <div class="auto-container">
                <div class="inner-container clearfix centered">
                  <h1>{accommodationType.pageDetails?.pageTitle}</h1>
                  <div class="page-nav">
                    <ul class="bread-crumb clearfix">
                      <li>
                        <Link to="/">{t("Home")}</Link>
                      </li>
                      <li>
                        <Link to="/accommodation">Accommodations</Link>
                      </li>
                      <li class="active">
                        {accommodationType.pageDetails?.pageTitle}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="rooms-section rooms-list">
            <div className="auto-container">
              <div
                className="sec-title centered"
                style={{ maxWidth: "100%" }}
                dangerouslySetInnerHTML={{
                  __html: accommodationType.pageDetails?.pageText,
                }}
              />

              <div className="rooms-box">
                {accommodationType.roomsList?.map((item, index) => (
                  <div className="room-block-four " key={item.roomUrl}>
                    <div className="inner-box">
                      <div className="image-box">
                        <figure className="image">
                          <img
                            src={item.roomPhoto}
                            alt={item.roomName}
                            title={item.roomName}
                          />
                        </figure>
                        <div
                          className="image-layer"
                          style={{ backgroundImage: `url(${item.roomPhoto})` }}
                        ></div>
                        <Link
                          className="overlink"
                          to={`/accommodation/${item.filterBy}/${item.roomUrl}`}
                        ></Link>
                      </div>
                      <div className="content-box">
                        <h4
                          style={{
                            fontSize: "40px",
                            fontFamily: "'Raleway', sans-serif",
                          }}
                        >
                          <Link
                            to={`/accommodation/${item.filterBy}/${item.roomUrl}`}
                          >
                            {item.roomName}
                          </Link>
                        </h4>
                        <div className="text">{item.roomSummery}</div>
                        <div
                          className="room-single"
                          style={{ paddingBottom: "1px" }}
                        >
                          <div
                            className="details-box"
                            style={{ paddingTop: "1px", marginTop: "1px" }}
                          >
                            <ul
                              className="info clearfix"
                              style={{
                                paddingLeft: "10px",
                                paddingTop: "10px",
                              }}
                            >
                              {item.roomView && (
                                <li>
                                  <span style={{ color: "#21acb7" }}>
                                    <i
                                      className="fa fa-binoculars"
                                      aria-hidden="true"
                                    ></i>
                                    <span style={{ paddingLeft: "5px" }}>
                                      {t("View")}:
                                    </span>
                                  </span>{" "}
                                  {item.roomView}
                                </li>
                              )}
                              {item.roomBed && (
                                <li>
                                  <span style={{ color: "#21acb7" }}>
                                    <i
                                      className="fa fa-bed"
                                      aria-hidden="true"
                                    ></i>
                                    <span style={{ paddingLeft: "5px" }}>
                                      {t("Bed")}:
                                    </span>
                                  </span>{" "}
                                  {item.roomBed}
                                </li>
                              )}
                              {item.roomSize && (
                                <li>
                                  <span style={{ color: "#21acb7" }}>
                                    <i
                                      className="fa fa-arrows-alt"
                                      aria-hidden="true"
                                    ></i>
                                    <span style={{ paddingLeft: "5px" }}>
                                      {t("Size")}:
                                    </span>
                                  </span>{" "}
                                  {item.roomSize}
                                </li>
                              )}
                              {item.maxOccupancy && (
                                <li>
                                  <span style={{ color: "#21acb7" }}>
                                    <span className="fa fa-user"></span>
                                    <span style={{ paddingLeft: "5px" }}>
                                      {t("Max Occupancy")}:
                                    </span>
                                  </span>{" "}
                                  {item.maxOccupancy}
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                        <div
                          className="link-row clearfix d-flex justify-content-between align-items-end"
                          style={{ paddingTop: "50px" }}
                        >
                          {item.roomPmscode && (
                            <>
                              <div
                                id={`fb-widget-${index}`}
                                className="fb-widget"
                                data-fbConfig={index}
                              ></div>
                              <script
                                className="fb-widget-config"
                                data-fbConfig={index}
                                type="application/json"
                              >
                                {JSON.stringify({
                                  params: [
                                    {
                                      startingFrom: {
                                        theme: "sf--thm-1",
                                        call2action: "none",
                                        roomRateFiltering: 2,
                                        rateFilter: [],
                                        roomFilter: [item.roomPmscode],
                                        dayRange: 180,
                                      },
                                      currency: "EGP",
                                      locale: item.bookingLang,
                                      pricesDisplayMode: "normal",
                                      offerComputeRule: "lowerPrice",
                                      maxAdults: 4,
                                      maxChildren: 0,
                                      mainColor: "#b0a06c",
                                      themeDark: false,
                                      openFrontInNewTab: true,
                                      property:
                                        accommodationType.pageDetails
                                          ?.hotelPMSCode,
                                      title:
                                        accommodationType.pageDetails
                                          ?.hotelName,
                                      // property: "egasw00001",
                                      // title: "BenBen by Dhara Hotels",
                                      childrenMaxAge: 12,
                                      groupsNames: "",
                                      quicksearch: { showChildrenAges: false },
                                      fbWidget: "StartingFrom",
                                    },
                                  ],
                                  commonParams: {
                                    redirectUrl:
                                      "https://redirect.fastbooking.com/DIRECTORY/dispoprice.phtml",
                                    showPropertiesList: false,
                                    demoMode: false,
                                    allowGroupSelection: false,
                                    groupName: "",
                                  },
                                  _authCode:
                                    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzY29wZXMiOiIuKiIsInByb3BlcnRpZXMiOiJlZ2FzdzAwMDAxIiwiZ3JvdXBzIjoiXiQiLCJmb3IiOiJCYWNrb2ZmaWNlIiwiaWF0IjoxNjQzODQyMDU1LCJqaWQiOiI2MWZhZWRlNzU5ZGJhIn0.EQ6Iyw6Vu82AaNXUfDsA3LqeoTY1Et930P3hVhoipdg",
                                  propertyIndex: 0,
                                  version: "1.46.0",
                                  baseHost: "websdk.fastbooking-services.com",
                                })}
                              </script>
                            </>
                          )}
                          <div
                            className="link-box ml-auto"
                            style={{ float: "right" }}
                          >
                            <Link
                              to={`/accommodation/${item.filterBy}/${item.roomUrl}`}
                              className="theme-btn btn-style-one"
                            >
                              <span className="btn-title">{t("Book Now")}</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <link rel="stylesheet" property="stylesheet" href="widgets/app.css" />
          <script type="text/javascript" src="widgets/app.js"></script>
        </>
      )}
    </>
  );
};
export default AccommodationType;
