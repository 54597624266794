import React, { useEffect, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { Alert, Snackbar, useMediaQuery } from "@mui/material";

import { useTranslation } from "react-i18next";
import Loader from "../../Loader";
import { fetchContactHotel } from "../../data/contactHotel/contactHotelSlice";
import { baseUrl } from "../../data/baseUrl";
// Import Fancybox CSS
const ContactUs = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();

  const language = useSelector((state) => state.language.language);
  const { contactHotel, loading, error } = useSelector(
    (state) => state.contactHotel
  );
  useEffect(() => {
    dispatch(fetchContactHotel(language));
  }, [dispatch, language]);

  const [formData, setFormData] = useState({
    customerName: "",
    customerEmail: "",
    customerPhone: "",
    customermessage: "",
    namehoney: "", // Clear honey pot fields after submission
    emailhoney: "",
  });
  const [loadingFormData, setLoadingFormData] = useState(false);
  const [errorFormDate, setErrorFormDate] = useState("");
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleCaptchaChange = (token) => {
  //   setRecaptchaToken(token);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!recaptchaToken) {
    //   setErrorFormDate("Please complete the reCAPTCHA.");
    //   setOpen(true);
    //   return;
    // }

    setLoadingFormData(true);
    setErrorFormDate("");
    setSuccess(false);

    try {
      const response = await fetch(
        `${baseUrl}/api/WSRequests/api/requests/requestContactUs`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            txt_CustomerName: formData.customerName,
            txt_CustomerEmail: formData.customerEmail,
            txt_CustomerMobile: formData.customerPhone,
            txt_CustomerMessage: formData.customermessage,

            // namehoney: formData.namehoney,
            // emailhoney: formData.emailhoney,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // const responseText = await response.text();
      // let result;
      // try {
      //   result = JSON.parse(responseText);
      // } catch (e) {
      //   throw new Error("Failed to parse response as JSON");
      // }

      setSuccess(true);
      setOpen(true);
    } catch (error) {
      console.error("Error:", error);
      setErrorFormDate(error.message); // Ensure the error is a string
      setOpen(true);
      setSuccess(false);
    } finally {
      setLoadingFormData(false);
      setFormData({
        customerName: "",
        customerEmail: "",
        customerPhone: "",
        customermessage: "",
        namehoney: "", // Clear honey pot fields after submission
        emailhoney: "",
      });
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#d8b560", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          {" "}
          <Helmet>
            <title>{contactHotel.pageDetails?.pageMetatagTitle}</title>
            <link
              rel="canonical"
              href={`https://www.dharahotels.com/${language}/aswan/benben-hotel/contact-us`}
            />
            <meta
              name="description"
              content={contactHotel.pageDetails?.pageMetatagDescription}
            />
            <meta
              property="og:title"
              content={contactHotel.pageDetails?.metatagTitleFacebook}
            />
            <meta
              property="og:description"
              content={contactHotel.pageDetails?.metatagDescriptionFacebook}
            />
            <meta
              name="twitter:title"
              content={contactHotel.pageDetails?.metatagTitleTwitter}
            />
            <meta
              name="twitter:description"
              content={contactHotel.pageDetails?.metatagDescriptionTwitter}
            />

            <meta name="robots" content="index, follow" />

            <style>
              {` .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }`}
            </style>

            <style>
              {`   
  .banner-carousel .slide-item:before {
      opacity: var(--opacity-color) !important;
  }
 

 
   .starting-from {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
        border: 0px solid #000 !important;
        display: -webkit-flex !important;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        font-size: 13px !important;
        -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
        margin: 0 auto !important;
        max-width: 1000px !important;
        padding: 1px !important;
    }

    .starting-from--txt {
        color: #21acb7;
    }

    .starting-from .starting-from--price {
        font-size: 22px !important;
        font-weight: 600;
        color: #d8b560 !important;
    }`}
            </style>
          </Helmet>
          <section class="page-banner">
            <div
              class="image-layer"
              style={{
                backgroundImage: `url(${
                  isMobile
                    ? contactHotel.pageDetails?.pageBannerMobile
                    : isTablet
                    ? contactHotel.pageDetails?.pageBannerTablet
                    : contactHotel.pageDetails?.pageBannerPC
                })`,
              }}
            ></div>
            <div class="banner-bottom-pattern"></div>

            <div class="banner-inner">
              <div class="auto-container">
                <div class="inner-container clearfix centered">
                  <h1>{contactHotel.pageDetails?.pageTitle}</h1>
                  <div class="page-nav">
                    <ul class="bread-crumb clearfix">
                      <li>
                        <Link to="/">{t("Home")}</Link>
                      </li>
                      <li class="active">
                        {contactHotel.pageDetails?.pageTitle}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>{" "}
          <section className="contact-section-two">
            <div className="auto-container clearfix">
              <div className="row clearfix">
                <div className="info-col col-lg-6 col-md-12 col-sm-12">
                  <div className="inner">
                    <div className="u-text">
                      {/* You can add content here if needed */}
                    </div>

                    <div className="info">
                      <div className="info-block">
                        <div className="block-inner">
                          <div
                            className="icon-box wow zoomInStable"
                            data-wow-delay="0ms"
                            data-wow-duration="2000ms"
                          >
                            <span className="flaticon-placeholder-1"></span>
                          </div>
                          <h4> {t("Address")} </h4>
                          <div className="text" style={{ color: "#000" }}>
                            {contactHotel.contactDetails?.hotelAddress}
                          </div>
                        </div>
                      </div>
                      <div className="info-block">
                        <div className="block-inner">
                          <div
                            className="icon-box wow zoomInStable"
                            data-wow-delay="300ms"
                            data-wow-duration="2000ms"
                          >
                            <span className="flaticon-technology-2"></span>
                          </div>
                          <h4>{t("Phone Number")}</h4>
                          <div className="text" style={{ color: "#000" }}>
                            <Link
                              to={`tel:${contactHotel.contactDetails?.hotelPhone}`}
                              style={{ color: "#000" }}
                            >
                              {contactHotel.contactDetails?.hotelPhone}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="info-block">
                        <div className="block-inner">
                          <div
                            className="icon-box wow zoomInStable"
                            data-wow-delay="600ms"
                            data-wow-duration="2000ms"
                          >
                            <span className="flaticon-email-1"></span>
                          </div>
                          <h4> {t("Email Address")} </h4>
                          <div className="text" style={{ color: "#000" }}>
                            <Link
                              to={`mailto:${contactHotel.contactDetails?.hotelEmail}`}
                              style={{ color: "#000" }}
                            >
                              {contactHotel.contactDetails?.hotelEmail}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-col col-lg-6 col-md-12 col-sm-12">
                  <div className="inner">
                    <div
                      className="form-box default-form contact-form-two wow fadeInRight"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <h3>{t("Have Any Question?")}</h3>
                      <div className="text" style={{ color: "#000" }}>
                        {t(
                          "Please complete the details below and then click on Submit and we’ll be in contact"
                        )}
                      </div>
                      <form id="contactform" onSubmit={handleSubmit}>
                        <label
                          className="ohnohoney"
                          htmlFor="namehoney"
                        ></label>
                        <input
                          tabIndex="-1"
                          hidden
                          className="ohnohoney"
                          autoComplete="off"
                          type="text"
                          id="namehoney"
                          name="namehoney"
                          placeholder="Your name here"
                          value={formData.namehoney}
                          onChange={handleInputChange}
                        />
                        <label
                          className="ohnohoney"
                          htmlFor="emailhoney"
                        ></label>
                        <input
                          hidden
                          tabIndex="-1"
                          className="ohnohoney"
                          autoComplete="off"
                          type="email"
                          id="emailhoney"
                          name="emailhoney"
                          placeholder="Your e-mail here"
                          value={formData.emailhoney}
                          onChange={handleInputChange}
                        />
                        <div className="form-group">
                          <div className="field-inner">
                            <input
                              type="text"
                              name="customerName"
                              placeholder={t("Customer name")}
                              required
                              value={formData.customerName}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="field-inner">
                            <input
                              type="email"
                              placeholder={t("Email")}
                              name="customerEmail"
                              required
                              value={formData.customerEmail}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="field-inner">
                            <input
                              type="text"
                              placeholder={t("Phone")}
                              name="customerPhone"
                              required
                              value={formData.customerPhone}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="field-inner">
                            <textarea
                              placeholder={t(
                                "Kindly let us know how we can assist you?"
                              )}
                              name="customermessage"
                              value={formData.customermessage}
                              onChange={handleInputChange}
                            ></textarea>
                          </div>
                        </div>

                        <div className="form-group">
                          <button className="theme-btn btn-style-one">
                            <span className="btn-title">
                              {" "}
                              {loadingFormData ? (
                                <>
                                  <Spinner
                                    animation="border"
                                    variant="light"
                                    size="sm"
                                  />{" "}
                                  {t("Loading")}...
                                </>
                              ) : (
                                t("Send")
                              )}
                            </span>
                          </button>
                        </div>
                      </form>{" "}
                      {!loadingFormData && success && (
                        <Snackbar
                          open={open}
                          autoHideDuration={6000}
                          onClose={handleClose}
                        >
                          <Alert
                            onClose={handleClose}
                            severity="success"
                            variant="filled"
                            sx={{ width: "100%" }}
                          >
                            {t("Your Request Sent Successfully")}{" "}
                          </Alert>
                        </Snackbar>
                      )}{" "}
                      {errorFormDate && (
                        <Snackbar
                          open={open}
                          autoHideDuration={4000}
                          onClose={handleClose}
                        >
                          <Alert
                            onClose={handleClose}
                            severity="danger"
                            variant="filled"
                            sx={{ width: "100%" }}
                          >
                            {errorFormDate}
                          </Alert>
                        </Snackbar>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="event-single" id="location">
            <div className="auto-container">
              <div className="lower-box" style={{ marginTop: "1px" }}>
                <span></span>
                <div className="row clearfix">
                  <div
                    className="info-block col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="inner">
                      <div className="info-inner">
                        <h3>{t("What's Nearby")}</h3>
                        <ul className="info">
                          {contactHotel.hotelNearBy?.map((item, index) => (
                            <li key={index}>
                              <strong>
                                <span className="flaticon-placeholder-1"></span>{" "}
                                {item.hotelNearByName} :{" "}
                                {item.hotelNearByDistance}{" "}
                                {item.hotelNearByDistanceUnit}
                              </strong>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="info-block col-lg-6 col-md-6 col-sm-12 ">
                    <div className="inner">
                      <iframe
                        src={contactHotel.contactDetails?.hotelGoogleMap}
                        width="100%"
                        height="550"
                        style={{ border: "0" }}
                        allowFullScreen=""
                        loading="lazy"
                        title="Google Maps"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ContactUs;
