import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl";

const initialState = {
  story: {},
  loading: false,
  error: null,
};

export const fetchStory = createAsyncThunk(
  "story/fetchStory",
  async (language) => {
    const response = await axios.get(
      `${baseUrl}/${language}/aswan/benben-hotel/story`
    );
    return response.data;
  }
);

const storySlice = createSlice({
  name: "story",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchStory.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStory.fulfilled, (state, action) => {
        state.loading = false;
        state.story = action.payload;
      })
      .addCase(fetchStory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default storySlice.reducer;
